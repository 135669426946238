import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import LabelledSearchableSwitchDropdown from "./LabelledSearchableSwitchDropdown";

const GenericFilters = ({ filterGroups, filterGroupSwitchStates, onSwitchChange, t, displayOptionsFunc = null }) => {
  if (Object.keys(filterGroups).length > 0) {
    return (
      <>
        <br />
        <Row>
          {Object.entries(filterGroups)
            .filter(([key, values]) => values.length > 1)
            .map(([key, values]) => (
              <Col
                key={"gen-f-" + key}
                className="d-flex flex-column justify-content-end"
              >
                <Form.Label>{t(key)}</Form.Label>
                <LabelledSearchableSwitchDropdown
                  options={values}
                  displayOptionsFunc={displayOptionsFunc}
                  placeholder={t("Filter")}
                  switchStates={filterGroupSwitchStates[key]}
                  onSwitchChange={onSwitchChange}
                  label={key}
                  variant="cyan"
                />
              </Col>
            ))}
          {Array(Math.max(0, 4 - Object.keys(filterGroups).length))
            .fill(null)
            .map((_, index) => (
              <Col key={`empty-${index}`} />
            ))}
        </Row>
      </>
    );
  }
  return null;
};

export default GenericFilters;
