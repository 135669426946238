import { graphColorsJSON } from "../../../Utils/GraphColors";
import { GenericGaugeSingleChart } from "./GenericGaugeSingleChart"
import { extractValuesFromJSON } from "../../../Utils/utilFunctions";

const npsConditions = ['Needs Improvement', 'Good', 'Great', 'Excellent'];
const npsRangeLabels = [" -100 to 0", ">0 to 30", ">30 to 70", ">70 to 100"];
const npsColors = extractValuesFromJSON(graphColorsJSON, ["danger", "blue", "cyan", "primary-teal"])
const npsRanges = [100, 30, 40, 30];
const npsRotation = (context) => {
    switch(context.dataIndex) {
        case 0: return -45;
        case 1: return 5;
        case 2: return 45;
        case 3: return 80;
        default: return 0;  // Optional default case
    }
}

export const NPSSingleChart = (props) => (
    <GenericGaugeSingleChart {...props}
        gaugeConditions={npsConditions}
        gaugeRangeLabels={npsRangeLabels}
        gaugeColors={npsColors}
        gaugeRanges={npsRanges}
        startValue={-100}
        metricTitle="NPS"
        rotationFunction={npsRotation}
    />);
