import React from "react";
import { Chart } from "react-chartjs-2";
import { getColor } from "../../../Utils/GraphColorPicker";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { modalCategoryOverlayOptions } from "../../../Utils/chartJsOptions";
import { applyTargetAnnotations } from "../../../Utils/utilFunctions";


// Accepts chartData and overlay (same format as chartData) as returned by the API and renders a chart
export default function OverlayNumericCategoricalBarChart(props) {
    // chartData => numeric
    // overlay => categorical
    let { chartData, target, overlay } = props;

    // finding modal categorical value
    const highestDcountObject = overlay.value.reduce((max, obj) => obj.dcount > max.dcount ? obj : max);
    const chartJsSetup = {
        labels: [chartData.name],
        datasets: [
            {
                label: chartData.name, // this is the top labels
                data: [chartData.value],
                backgroundColor: getColor(0),
                borderRadius: 8,
                yAxisID: 'y'
            },
        ]
    };

    let options = modalCategoryOverlayOptions(highestDcountObject.value, highestDcountObject.dcount, chartData.y_max)
    options = applyTargetAnnotations(options, target, chartData);
    return (
        <Chart
            type="bar"
            data={chartJsSetup}
            options={options}
            plugins={[ChartDataLabels]}
        />
    );
}
