import { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import useFetch from "use-http";
import Card from "react-bootstrap/Card";
import Badge from 'react-bootstrap/Badge';
import Modal from 'react-bootstrap/Modal';
import useFetchConfig from "../../Hooks/useFetchConfig";

import { CardHeader } from "react-bootstrap";

export default function DashboardReportingSelectionPage() {
  const navigate = useNavigate();
  const fetchConfig = useFetchConfig();
  const { get, post, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [rowGreyedOut, setRowGreyedOut] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  useEffect(() => {
    fetchExtraReporting();
  }, [id]);

  const fetchExtraReporting = async () => {
    let data = await get(`/api/dashboard/${id}/extra-reporting`);
    if (response.ok) {
      setRowGreyedOut(!data.extra_reporting);
    }
  }

  const contactElevate = async () => {
    setIsLoading(true);
    let data = await post(`/api/dashboard/${id}/extra-reporting`);
    setIsLoading(false);
    if (!response.ok) {
      setError("Extra reporting request failed. Try again please.");
      return
    }
    setError(null);
    setRowGreyedOut(!data.extra_reporting);
    handleClose();
  }

  return (
    <>
      <Modal show={showModal} onHide={handleClose} backdrop={false} centered className={isLoading ? 'loading-cursor' : ''}>
        <Modal.Header className="bg-light">
          <Modal.Title className="text-purple">Additional Campaign Reporting</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          Click "Yes" for your Impact Account Manager to contact you to incorporate additional campaign reporting.
        </Modal.Body>
        <Modal.Footer className="bg-light">
          {isLoading ? (<div>Sending...</div>) : ( 
            <>
              <Button variant="danger text-white" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary text-white" onClick={contactElevate} >
                Yes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>

      <Col xs={8} md={6} lg={6} xl={6} className={isLoading ? 'loading-cursor mx-auto' : 'mx-auto'} bg="light">
        {/* Overall card */}
        <Card bg="white" className="m-3">
          <CardHeader className="mx-3">
            <br />
            <h1 className="text-dark">Reporting & Analysis</h1>
            <br />
          </CardHeader>
          {error && <p className="text-danger">{error}</p>}
          {/* Reporting included row */}
          <Row>
            <Col xs={12} md={12} lg={6} xl={6} className="mx-auto">
              <Card bg="white" className="m-4">
                <CardHeader>
                  <h4 className="text-dark">
                    In Campaign<br />
                    <b>Reporting Included</b><br />
                  </h4>

                </CardHeader>
                <Card bg="secondary" border="purple">
                  <Card.Body>
                    <Card.Title><Badge bg="purple">Automated</Badge></Card.Title>
                    <Card.Text><h5 className="text-light">DAILY INSIGHTS REPORT</h5></Card.Text>
                  </Card.Body>
                </Card>
              </Card>
            </Col>
            <Col xs={12} md={12} lg={6} xl={6} className="mx-auto">
              <Card bg="white" className="m-4">
                <CardHeader>
                  <h4 className="text-dark">
                    Post Campaign<br />
                    <b>Reporting Included</b><br />
                  </h4>

                </CardHeader>
                <Card bg="secondary">
                  <Card.Body>
                    <Card.Title><Badge bg="info">Data Analyst</Badge></Card.Title>
                    <Card.Text><h5 className="text-light">CAMPAIGN SUMMARY REPORT</h5></Card.Text>
                  </Card.Body>
                </Card>
              </Card>
            </Col>
          </Row>

          {/* Additional reporting row */}
          <Row className={rowGreyedOut ? 'greyed-out' : ''}>
            <Col xs={12} md={12} lg={6} xl={6} className="mx-auto">
              <Card bg="white" className="m-4">
                <CardHeader>
                  <h4 className="text-dark">
                    Additional in Campaign<br />
                    <b>Reporting</b><br />
                  </h4>

                </CardHeader>
                <Card bg="secondary" border="purple" className="mb-3">
                  <Card.Body>
                    <Card.Title><Badge bg="purple">Automated</Badge></Card.Title>
                    <Card.Text><h5 className="text-light">BENCHMARK ALERT</h5></Card.Text>
                  </Card.Body>
                </Card>
                <Card bg="secondary" className="mb-3">
                  <Card.Body>
                    <Card.Title><Badge bg="info">Data Analyst</Badge></Card.Title>
                    <Card.Text><h5 className="text-light">In CAMPAIGN INSIGHTS REPORT</h5></Card.Text>
                  </Card.Body>
                </Card>
              </Card>
            </Col>
            <Col xs={12} md={12} lg={6} xl={6} className="mx-auto">
              <Card bg="white" className="m-4">
                <CardHeader>
                  <h4 className="text-dark">
                    Additional Post Campaign<br />
                    <b>Reporting</b><br />
                  </h4>
                </CardHeader>
                <Card bg="secondary" className="mb-3">
                  <Card.Body>
                    <Card.Title><Badge bg="info">Data Analyst</Badge></Card.Title>
                    <Card.Text><h5 className="text-light">INFOGRAPHIC SUMMARY REPORT</h5></Card.Text>
                  </Card.Body>
                </Card>
                <Card bg="secondary" className="mb-3">
                  <Card.Body>
                    <Card.Title><Badge bg="info">Data Analyst</Badge></Card.Title>
                    <Card.Text><h5 className="text-light">FULL ANALYSIS INSIGHTS REPORT</h5></Card.Text>
                  </Card.Body>
                </Card>
              </Card>
            </Col>
          </Row>
          <Card.Body className="mx-3  ">
            <Row className="justify-content-evenly">
              <Col xs={10} md={10} lg={4} xl={4} className="mx-auto">
                <Button variant="secondary" className={rowGreyedOut ? 'w-100' : 'greyed-out w-100'} onClick={handleShow} >Unlock</Button>
              </Col>
              <Col xs={10} md={10} lg={4} xl={4} className="mx-auto">
                <Button
                  variant="primary"
                  className="w-100 text-white"
                  onClick={() => { navigate(`/dashboard-edit/${id}`) }}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card >
      </Col>
    </>
  );
}
