import React from "react";
import CategoricalSingleChart from "../Categorical/CategoricalSingleChart";
import OverlayNumericCategoricalBarChart from "./OverlayNumericCategoricalBarChart";
import OverlayCategoricalOnCategoricalHeatMap from "./OverlayCategoricalOnCategoricalHeatMap";
// Accepts chartData and overlay (same format as chartData) as returned by the API and renders a chart
export default function OverlayCategoricalSingleChart(props) {
    const { chartData, overlay, dropdownSelection } = props;
    if (!overlay || overlay.value === undefined || overlay.value === "No data." || overlay.categorical === undefined) {
        return (
            <CategoricalSingleChart
                chartData={chartData}
                dropdownSelection={dropdownSelection}
            />
        );
    }
    if (overlay && overlay.categorical) {
        return <OverlayCategoricalOnCategoricalHeatMap overlay={overlay} />
    }

    return (
        <CategoricalSingleChart
            chartData={chartData}
            dropdownSelection={dropdownSelection}
        />
    );
}
