import { CardHeader, Card, Form, Col } from "react-bootstrap";

export default function TrickyTextInputCard({
  externalState,
  handleFormChange,
  cardError,
  setCardError,
  cardHeader,
  controlId,
  label,
  placeholder,
  maxLength,
  disabled=false,
}) {

  return (
    <Col>
      <Card className="h-100">
        <CardHeader>{cardHeader}</CardHeader>
        <Card.Body className="flex-grow-1 d-flex flex-column">
          <Form.Group
            controlId={controlId}
            className="flex-grow-1 d-flex flex-column"
          >
            <Form.Label>{label}</Form.Label>
            <Form.Control
              onChange={handleFormChange}
              placeholder={placeholder}
              className="mt-auto"
              maxLength={maxLength}
              disabled={disabled}
            />
          </Form.Group>
        </Card.Body>
        <Card.Footer className="text-center">
          {setCardError && <p className="text-danger">{cardError}</p>}
          {externalState}
        </Card.Footer>
      </Card>
    </Col>
  );
}
