import React from "react";
import { Chart } from "react-chartjs-2";
import { getColor } from "../../../Utils/GraphColorPicker";
import {
  chartJsDefaultOptions,
  createCategoricalLabels,
} from "../../../Utils/chartJsOptions";
import { prepareCategoricalData } from "../../../Utils/utilFunctions";
import { Col, Container, Row } from "react-bootstrap";

// for more detailed example options: https://react-chartjs-2.js.org/examples/pie-chart/
export default function CategoricalGroupPieChart(props) {
  const { chartData, dropdownSelection } = props;
  if (chartData.value === undefined) return <></>;
  if (dropdownSelection === undefined) return <></>;

  let legendSet = new Set();
  Object.entries(chartData.value).map((value, index) => {
    value[1].forEach((item) => {
      legendSet.add(item.value);
    })
  });
  const legendArray = Array.from(legendSet);
  const backgroundColorMap = {};
  legendArray.forEach((label, index) => {
    backgroundColorMap[label] = getColor(index);
  })

  const CustomLegend = ({ labels }) => {
    return (
      <div className="custom-legend text-black">
        {labels.map((label, index) => (
          <div key={index}>
            <span
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                backgroundColor: backgroundColorMap[label], // Use the map
                marginRight: "8px",
                verticalAlign: "middle",
              }}
            ></span>
            <span>{label}</span>
          </div>
        ))}
      </div>
    );
  };

  // data reference
  //  const data = {
  //   labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  //   datasets: [
  //     {
  //       label: '# of Votes',
  //       data: [12, 19, 3, 5, 2, 3],
  //       backgroundColor: [
  //         'rgba(255, 99, 132, 0.2)',
  //         'rgba(54, 162, 235, 0.2)',
  //         'rgba(255, 206, 86, 0.2)',
  //         'rgba(75, 192, 192, 0.2)',
  //         'rgba(153, 102, 255, 0.2)',
  //         'rgba(255, 159, 64, 0.2)',
  //       ],
  //       borderColor: [
  //         'rgba(255, 99, 132, 1)',
  //         'rgba(54, 162, 235, 1)',
  //         'rgba(255, 206, 86, 1)',
  //         'rgba(75, 192, 192, 1)',
  //         'rgba(153, 102, 255, 1)',
  //         'rgba(255, 159, 64, 1)',
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  return (
    <Container>
      <Row>
        {chartData.value &&
          Object.entries(chartData.value).map((value, index) => {
            let valuesArray = [];
            let sumCount = 0;
            value[1] = prepareCategoricalData(value[1]);
            value[1].forEach((item) => {
              valuesArray.push(item.value);
              sumCount = sumCount + item.dcount;
            });
            const data = {
              labels: valuesArray, // You can have more labels if there are more data points
              datasets: [
                value[1].reduce(
                  (acc, value) => {
                    acc.data.push([value.displayValue]);
                    acc.count.push([value.dcount]);
                    acc.backgroundColor.push(backgroundColorMap[value.value]);
                    return acc;
                  },
                  { data: [], count: [], backgroundColor: [] },
                ),
              ],
            };
            let options = {
              ...chartJsDefaultOptions,
              plugins: {
                datalabels: {
                  ...chartJsDefaultOptions.plugins.datalabels,
                  labels: createCategoricalLabels(dropdownSelection),
                },
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  text: value[0],
                  padding: {
                    top: 10,
                    bottom: 30,
                  },
                },
                subtitle: {
                  display: true,
                  text: `Sample Size:${sumCount}`,
                  padding: {
                    top: 10,
                    bottom: 30,
                  },
                  position: "bottom",
                },
              },
            };
            return (
              <Col key={options.title} className="p-0" md={4}>
                <Chart
                  type="pie"
                  data={data}
                  options={options}
                  style={{ width: "100%", height: "100%" }}
                />
              </Col>
            );
          })
        }
      </Row>
      <Row>
        <CustomLegend labels={legendArray} />
      </Row>
    </ Container>
  );
}
