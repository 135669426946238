import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import useFetch from "use-http";

import { MainContext } from "../Providers/MainContext";
import useFetchConfig from "../Hooks/useFetchConfig";
import useFilterStore from "../Hooks/useFilterStore";

export default function LoginRedirect() {
  const fetchConfig = useFetchConfig();
  const { get } = useFetch(process.env.REACT_APP_API_URL, fetchConfig);
  const navigate = useNavigate();
  const { customer } = useContext(MainContext);
  const { resetAllFilters } = useFilterStore();

  useEffect(() => {
    fetchDashboards();
  }, [customer]);

  const fetchDashboards = async () => {
    if (customer.id) {
      let data = await get(`/api/customer/${customer.id}/dashboards`);
      if (data && data.length === 1) {
        // isFiltersRetained is set to true
        resetAllFilters(true);
        navigate(`/dashboard/${data[0].id}`);
      } else {
        navigate("/home");
      }
    }
  };
  return (
    <Row>
      <Col>
        <br />
        <h1 className="text-white text-center" >Thank you for registering with Impact!</h1>
        <p className="text-white text-center" >You’re now ready to be assigned to Dashboards. Please let the Impact team know that you are registered, and they will assign you to the relevant dashboards.</p>
      </Col>
    </Row>
  )
}
