import { useState } from "react";
import { CardHeader, Card, Form, Col } from "react-bootstrap";

export default function SelectInputCard({
  options,
  externalState,
  setExternalState,
  cardHeader,
  controlId,
  label,
  initialErrorGenerator = (cardHeader) => "",
  disabled = false,
}) {
  const [internalError, setInternalError] = useState(
    initialErrorGenerator(cardHeader),
  );
  const handleFormChange = (event) => {
    setExternalState(event.target.value);
    setInternalError("");
  };

  return (
    <Col>
      <Card className="h-100">
        <CardHeader>{cardHeader}</CardHeader>
        <Card.Body className="flex-grow-1 d-flex flex-column">
          <Form.Group
            controlId={controlId}
            className="flex-grow-1 d-flex flex-column"
          >
            <Form.Label>{label}</Form.Label>
            <Form.Select
              onChange={handleFormChange}
              className="mt-auto"
              disabled={disabled}
            >
              <option value="">Choose...</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Card.Body>
        <Card.Footer className="text-center">
          {internalError && <p className="text-danger">{internalError}</p>}
          {externalState}
        </Card.Footer>
      </Card>
    </Col>
  );
}
