import React from "react";
import { Chart } from "react-chartjs-2";
import { graphColors } from "../../../Utils/GraphColors";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { modalCategoryOverlayOptions } from "../../../Utils/chartJsOptions";
import { simplifyDataForNotGauge } from "../../../Utils/utilFunctions";
import zoomPlugin from "chartjs-plugin-zoom";
import { applyTargetAnnotationsToGrouped } from "../../../Utils/utilFunctions";

// Accepts Chart Data as Returned by the API and renders a chart
// This class should be the only thing that changes if the api output does
export default function OverlayNumericCategoricalGroupBarChart(props) {
    // chartData => numeric
    // overlay => categorical
    const { chartData, targets, overlay } = props;

    // Handle Gauge type adjustment if necessary
    simplifyDataForNotGauge(chartData.value);
    simplifyDataForNotGauge(overlay.value);

    const chartJsSetup = {
        labels: Object.keys(chartData.value),
        datasets: [
            {
                label: chartData.name,
                data: Object.values(chartData.value),
                backgroundColor: graphColors[0], // Single color for all chartData bars
                borderRadius: 8,
                yAxisID: "y",
            },
        ],
    };

    let options = modalCategoryOverlayOptions(overlay.value, 0, chartData.y_max);
    options = applyTargetAnnotationsToGrouped(options, targets, chartData);

    return (
        <Chart
            type="bar"
            data={chartJsSetup}
            options={options}
            plugins={[ChartDataLabels, zoomPlugin]}
        />
    );
}
