import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import useFetch from "use-http";
import useFetchConfig from "../../Hooks/useFetchConfig";
import { useTranslation } from "react-i18next";

export function AddSurvey({
  dashboardId,
  modalOpen,
  setModalOpen,
}) {
  const fetchConfig = useFetchConfig();
  const { post, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const [error, setError] = useState(null);
  const [title, setTitle] = useState("");
  const [source, setSource] = useState("typeform");
  const [formId, setFormId] = useState("");
  const [geoMapping, setGeoMapping] = useState({"location": "location"});
  const [mrchMapping, setMrchMapping] = useState({"brand": "brand"});
  const [fakeDate, setFakeDate] = useState(false)

  const { t } = useTranslation();

  const postSurvey = async () => {
    const payload = {
      dashboard_id: dashboardId,
      title: title,
      source: source,
      form_id: formId,
      geo_mappings: geoMapping,
      mrch_mappings: mrchMapping,
      fake_date: fakeDate,
    };
    let data = await post(`/api/survey/add`, payload);
    if (response.ok) {
      if (data?.error) {
        setError(data.error);
      } else {
        setError(null);
        setModalOpen(false);  
      }
    } else {
      setError("Survey post failed.");
    }
  };

  return (
    <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
      <Modal.Header className="bg-light">
        <Modal.Title>
          {t(`Add Survey`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        {error && <div className="alert alert-danger">{error}</div>}{" "}
        {/* Display error message */}
        <Row>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="title">
              <Form.Label>{t("Title")}</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="e.g., EOS_UK_EXP01001_Example; paste from TF"
                className="bg-light"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="source">
              <Form.Label>{t("Source")}</Form.Label>
              <Form.Control
                type="text"
                value={source}
                onChange={(e) => setSource(e.target.value)}
                placeholder="e.g., typeform"
                className="bg-light"
                disabled={true}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="formId">
              <Form.Label>{t("Form ID")}</Form.Label>
              <Form.Control
                type="text"
                value={formId}
                onChange={(e) => setFormId(e.target.value)}
                placeholder="e.g., KOQxdFuY; paste from TF"
                className="bg-light"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="geoMapping">
              <Form.Label>{t("Geo Mapping")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={JSON.stringify(geoMapping)}
                onChange={(e) => {
                  try {
                    setGeoMapping(JSON.parse(e.target.value));
                  } catch {
                    // Optionally show feedback if JSON parsing fails
                  }
                }}
                placeholder='{ "location": "location" }'
                className="bg-light"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="mrchMapping">
              <Form.Label>{t("Merchandise Mapping")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={JSON.stringify(mrchMapping)}
                onChange={(e) => {
                  try {
                    setMrchMapping(JSON.parse(e.target.value));
                  } catch {
                    // Optionally show feedback if JSON parsing fails
                  }
                }}
                placeholder='{ "brand": "brand" }'
                className="bg-light"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="fakeDate">
              <Form.Label>{t("Fake Date")}</Form.Label>
              <Form.Check
                type="checkbox"
                label={t("Use Fake Date")}
                checked={fakeDate}
                onChange={(e) => setFakeDate(e.target.checked)}
                className="bg-light"
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button variant="secondary" onClick={() => setModalOpen(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={postSurvey}>
          Add Survey
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
