import { graphColors } from "./GraphColors";

export function getColor(index) {
  return graphColors[index % graphColors.length];
}

export function getColorSingleWordcloud(index) {
  // Convert legend string to a unique numeric hash
  if (index < 10) {
    return graphColors[0];
  } else if (index < 30) {
    return graphColors[1];
  }
  return graphColors[2];
}

export function getColorGroupWordcloud(index) {
  // Convert legend string to a unique numeric hash
  if (index < 3) {
    return graphColors[0];
  } else if (index < 5) {
    return graphColors[3];
  }
  return graphColors[4];
}
