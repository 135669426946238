import React from "react";
import { Card, CardHeader, Col, Row } from "react-bootstrap";
import {
  MiniButton,
  TitleButton,
  HeadlineButtons,
  ExportButton,
  FiltersButton,
  RefreshButton,
  Cell,
  OpsTable,
} from "./utils";
import {
  dbToLocale,
  formatStdDateLocaleTime,
} from "../../../Utils/utilFunctions";

export default function OpsTermsConditions({
  data,
  filterParams,
  handleExport,
  isLoading,
  deliberateRefreshes,
  setDelberateRefreshes,
}) {
  const { groupBy, setGroupBy, selectedFilterGroups, setSelectedFilterGroups, handleToggleFilterModal } = filterParams;
  const rows = data.value.rows;
  if (rows === undefined) return <></>;
  const columns = data.value.columns;
  const headline = data.value.headline;
  const primaryButtonColumns = ["Staff Booked", "Unsigned", "Undated"];
  const secondary = data.value.secondary;
  const secondaryButtonColumns = ["n/a"];

  const buttonConfig = [
    {
      content: `Booked: ${headline.size}`,
      tooltip: "Freelancers with bookings",
    },
    {
      content: `Signed-In: ${headline.signed_in} / ${headline.size} = ${Math.round((100 * headline.signed_in) / headline.size)}%`,
      tooltip: "Freelancers who have signed in / Freelancers with bookings",
    },
    {
      content: `T&C Signed: ${headline.signed} / ${headline.size} = ${Math.round((100 * headline.signed) / headline.size)}%`,
      tooltip: "Freelancers with terms & conditions signed / Freelancers with bookings",
    },
  ];

  const cellToColor = (row, column) => {
    let variantColor = "primary";
    if (column === "Unsigned" || column === "Undated") {
      if (row[column] > 0) {
        if (row[column] / row["Staff Booked"] > 0.2) {
          variantColor = "danger";
        } else {
          variantColor = "purple";
        }
      }
    }
    return variantColor;
  };
  const assignCell = (row, column, rowIndex, args) => {
    const key = `${rowIndex}-${column}`;
    const buttonColumns = args["buttonColumns"];
    if (buttonColumns.includes(column) && groupBy !== column) {
      return (
        <MiniButton
          key={key}
          row={row}
          column={column}
          updateConfig={[{ key: "job_id", source: "Job ID" }]}
          cellToColor={cellToColor}
          setGroupBy={setGroupBy}
          selectedFilterGroups={selectedFilterGroups}
          setSelectedFilterGroups={setSelectedFilterGroups}
          isLoading={isLoading}
        />
      );
    } else if (column === "Job Name") {
      return (
        <Cell
          key={key}
          value={
            <a
              href={`https://portal-uk.weareelevate.global/jobs/${row["Job ID"]}`}
              target="_blank"
              rel="noreferrer"
            >
              {row["Job Name"]}
            </a>
          }
          tooltip={column}
        />
      );
    } else if (column === "Name") {
      return (
        <Cell
          key={key}
          value={
            <a
              href={`https://portal-uk.weareelevate.global/administration/freelancers/profile/${row["ID"]}`}
              target="_blank"
              rel="noreferrer"
            >
              {row["Name"]}
            </a>
          }
          tooltip={column}
        />
      );
    } else if (column === "T&C Dated" || column === "Last Login At") {

      return (
        <Cell
          key={key}
          clss=""
          value={formatStdDateLocaleTime(row[column], dbToLocale(row["db"]))}
          tooltip={column}
        />
      );
    } else {
      return <Cell key={key} clss="" value={row[column]} tooltip={column} />;
    }
  };

  const header = () => {
    return (
      <Row className="d-flex justify-content-between align-items-center">
        <Col>
          {groupBy ? (
            <TitleButton
              title={data.name}
              deleteKeys={["job_id"]}
              setGroupBy={setGroupBy}
              setSelectedFilterGroups={setSelectedFilterGroups}
              isLoading={isLoading}
            />
          ) : (
            <h5>{data.name}</h5>
          )}
        </Col>
        <Col className="mx-auto"></Col>
        <Col xs="auto">
          <HeadlineButtons buttonConfig={buttonConfig} isLoading={isLoading} />
          <ExportButton isLoading={isLoading} handleExport={handleExport} />
          <FiltersButton
            isLoading={isLoading}
            handleToggleFilterModal={handleToggleFilterModal}
          />
          <RefreshButton
            isLoading={isLoading}
            deliberateRefreshes={deliberateRefreshes}
            setDeliberateRefreshes={setDelberateRefreshes}
          />
        </Col>
      </Row>
    );
  };
  return (
    <Card>
      <CardHeader>{header()}</CardHeader>
      <Card.Body>
        <OpsTable
          columns={columns}
          rows={rows}
          assignCell={assignCell}
          args={{ buttonColumns: primaryButtonColumns }}
        />
        {secondary && (
          <OpsTable
            columns={secondary.columns}
            rows={secondary.rows}
            assignCell={assignCell}
            args={{ buttonColumns: secondaryButtonColumns }}
          />
        )}
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
}
