import React, { useState, useMemo } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

export default function SearchableSwitchDropdown({ options, switchStates, onSwitchChange, placeholder, variant, displayOptionsFunc }) {
  const [value, setValue] = useState("");

  // Memoize transformed display options to avoid recalculating on each render
  const displayOptions = useMemo(() => options.map((option) => ({
    core: option,
    display: displayOptionsFunc ? displayOptionsFunc(option) : option,
  })), [options, displayOptionsFunc]);

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div ref={ref} className={className} aria-labelledby={labeledBy}>
          <Form.Control
            autoFocus
            className="mx-3 my-2 w-auto bg-light placeholder-grey"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {displayOptions
              .filter(({ display }) => !value || display.toLowerCase().includes(value.toLowerCase()))
              .map(({ core, display }) => (
                <li key={core} className="mx-3 my-1">
                  <Form.Switch
                    id={`switch-${core}`}
                    label={display} // Use display value for the label
                    checked={switchStates[core]} // Use core value for checking state
                    onChange={() => onSwitchChange(core, !switchStates[core])} // Pass core value for updates
                    className="grey-green-switch btn-primary"
                  />
                </li>
              ))}
          </ul>
        </div>
      );
    }
  );

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle id="dropdown-autoclose-outside" variant={variant}>
        {placeholder}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} className="bg-light" />
    </Dropdown>
  );
}
