import { useState } from "react";
import { CardHeader, Card, Form, Col } from "react-bootstrap";

export default function SuggestedTextInputCard({
  labelName,
  externalState,
  setExternalState,
  suggestionOptions,
  cardHeader,
  controlId,
  label,
  placeholder,
  maxLength,
  disabled=false,
}) {
  const [internalError, setInternalError] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleFormChange = (event) => {
    const { value } = event.target;
    if (value.length > 63) {
      setInternalError(
        `If your ${labelName} is too long, it won't fit in the database field.`,
      );
    } else {
      setInternalError("");
    }
    setExternalState(value);
    setSuggestions(
      value
        ? suggestionOptions.filter((item) =>
            item.toLowerCase().startsWith(value.toLowerCase()),
          )
        : [],
    );
  };

  const handleSuggestion = (event) => {
    setExternalState(event.target.textContent);
    setSuggestions([]);
  };

  return (
    <Col>
      <Card className="h-100">
        <CardHeader>{cardHeader}</CardHeader>
        <Card.Body className="flex-grow-1 d-flex flex-column">
          <Form.Group
            controlId={controlId}
            className="flex-grow-1 d-flex flex-column"
          >
            <Form.Label>{label}</Form.Label>
            <Form.Control
              onChange={handleFormChange}
              placeholder={placeholder}
              value={externalState}
              className="mt-auto"
              maxLength={maxLength}
              disabled={disabled}
            />
            {suggestions && suggestions.length > 0 ? (
              <Form.Label>
                <ul>
                  {suggestions.map((item, index) => (
                    <li key={index} onClick={handleSuggestion}>
                      {item}
                    </li>
                  ))}
                </ul>
              </Form.Label>
            ) : null}
          </Form.Group>
        </Card.Body>
        <Card.Footer className="text-center">
          {internalError && <p className="text-danger">{internalError}</p>}
          {externalState}
        </Card.Footer>
      </Card>
    </Col>
  );
}
