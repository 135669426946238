import React, { useRef, useEffect, useState } from "react";
import { WordCloudChart } from 'chartjs-chart-wordcloud';
import { Col, Container } from "react-bootstrap";
import { getColorGroupWordcloud } from "../../../Utils/GraphColorPicker";
import { sortArrayByDcountAndValue } from "../../../Utils/utilFunctions";

export function WordcloudWithinGroup(props) {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  const { chartData, label } = props;
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  const scaleDcount = (dcount, minSize, maxSize, minDcount, maxDcount) => {
    if (maxDcount === minDcount) {
      return maxSize * 0.45;
    }
    const sizeRange = maxSize - minSize;
    const scaledSize = ((dcount - minDcount) / (maxDcount - minDcount)) * sizeRange + minSize;
    return Math.floor(scaledSize) * 0.45;
  };

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        setContainerSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight
        });
      }
    };

    const resizeObserver = new ResizeObserver(updateSize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    if (canvasRef.current && !chartRef.current && containerSize.width > 0 && containerSize.height > 0) {
      const ctx = canvasRef.current.getContext('2d');
      const slicedChartData = sortArrayByDcountAndValue(chartData).slice(0, 100);
      const maxDcount = Math.max(...slicedChartData.map(d => d.dcount));
      const minDcount = Math.min(...slicedChartData.map(d => d.dcount));

      const dataWithFontSize = slicedChartData.map((d, index) => ({
        ...d,
        // Scale dcount to a range of 30-90; play around 15 - 100 to get good results
        size: scaleDcount(d.dcount, 30, 90, minDcount, maxDcount),
        color: getColorGroupWordcloud(index)
      }));

      chartRef.current = new WordCloudChart(ctx, {
        type: 'wordCloud',
        data: {
          labels: dataWithFontSize.map(d => d.value),
          datasets: [{
            data: dataWithFontSize.map(d => ""),
            color: dataWithFontSize.map(d => d.color),
            size: dataWithFontSize.map(d => d.size),
            dcount: dataWithFontSize.map(d => d.dcount),
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  return `${context.label}: ${context.dataset.dcount[context.dataIndex]} (Sample Size: ${chartData.length})`;
                },
                // rectangle color when hovered
                labelColor: function (context) {
                  return {
                    backgroundColor: context.dataset.color[context.dataIndex],
                  };
                },
              }
            },
            // disable legend to avoid overlapping labels
            legend: {
              display: false
            },
          },
          layout: {
            padding: {
              top: 20,
              right: 20,
              bottom: 20,
              left: 20
            }
          },
          elements: {
            word: {
              minRotation: 0,
              maxRotation: 0,
            }
          }
        }
      });
    }

    return () => {
      // Cleanup chart on unmount
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [chartData, containerSize]);

  return (
    <Container className="mt-1" ref={containerRef}>
      <Col xs={12} className="text-center">
        <div className="text-black display-8">{label}</div>
      </Col>
      <Col xs={12} className="text-center" style={{ height: "400px", position: "relative" }}>
        <canvas ref={canvasRef} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} />
      </Col>
    </Container>
  );
}