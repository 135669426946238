import { useState, useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import useFetch from "use-http";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { CardHeader } from "react-bootstrap";

import useFetchConfig from "../../Hooks/useFetchConfig";

import { MainContext } from "../../Providers/MainContext";

export default function DashboardCreatePage() {
  const navigate = useNavigate();
  const fetchConfig = useFetchConfig();
  const { get, post, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const { customer } = useContext(MainContext);
  const [locations, setLocations] = useState(null);
  const [error, setError] = useState(null);
  const [countryFilter, setCountryFilter] = useState("");
  const [regionFilter, setRegionFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [dashboardName, setDashboardName] = useState("");
  const [dashboardCategory, setDashboardCategory] = useState("bronze");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [formType, setFormType] = useState("EOS");
  const [formDb, setFormDb] = useState("UK");
  const [formJobCode, setFormJobCode] = useState("EXP01001");
  const [formName, setFormName] = useState("My Form Name");
  const [formTitle, setFormTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [merch, setMerch] = useState(null);
  const [brandFilter, setBrandFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [productFilter, setProductFilter] = useState("");
  const [selectedMerch, setSelectedMerch] = useState([]);

  const [country, setCountry] = useState(null);
  const [region, setRegion] = useState(null);
  const [city, setCity] = useState(null);
  const [location, setLocation] = useState(null);

  const [brand, setBrand] = useState(null);
  const [category, setCategory] = useState(null);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    fetchLocations();
    fetchMerch();
  }, [customer]);

  useEffect(() => {
    setFormTitle(
      `${formType}_${formDb}_${formJobCode}_${replaceNonAlphaNumericWithDashes(formName)}`,
    );
  }, [formType, formDb, formJobCode, formName]);

  const replaceNonAlphaNumericWithDashes = (str) =>
    str.replace(/[^a-zA-Z0-9]/g, "-");
  const handleJobCodeChange = (event) => {
    const { value } = event.target;
    // Regular expression to match only alphanumeric characters
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, "");
    setFormJobCode(filteredValue);
  };

  const fetchLocations = async () => {
    if (!customer.id) return;
    let data = await get(`/api/customer/${customer.id}/stores`);
    if (response.ok) {
      setError(null);
      let _selectedLocations = [];
      data.forEach((_location) => {
        _selectedLocations.push(_location.id);
      });
      setSelectedLocations(_selectedLocations);
      setLocations(data);
    } else {
      setError("Error Loading Locations");
    }
  };

  const handleLocationCreateSubmit = async () => {
    const errorMessage = [];
    if (!country) {
      errorMessage.push("Please fill in a country.");
    }
    if (!region) {
      errorMessage.push("Please fill in a region.");
    }
    if (!city) {
      errorMessage.push("Please fill in a city.");

    }
    if (!location) {
      errorMessage.push("Please fill in a location.");
    }
    if (errorMessage.length > 0) {
      setError(
        <div>
          {errorMessage.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </div>
      );
      return;
    }

    await post(`/api/customer/${customer.id}/stores`, {
      country,
      region,
      city,
      location,
    });
    if (response.ok) {
      fetchLocations()
      setError(null);
    } else {
      setError("Error creating location.");
    }
  };


  const fetchMerch = async () => {
    if (!customer.id) return;
    let data = await get(`/api/customer/${customer.id}/merch`);
    if (response.ok) {
      setError(null);
      let _selectedMerch = [];
      data.forEach((_mrch) => {
        _selectedMerch.push(_mrch.id);
      });
      setSelectedMerch(_selectedMerch);
      setMerch(data);
    } else {
      setError("Error loading merch.");
    }
  };

  const handleMerchCreateSubmit = async () => {
    const errorMessage = [];
    if (!brand) {
      errorMessage.push("Please fill in a brand.");
    }
    if (!category) {
      errorMessage.push("Please fill in a category.");
    }
    if (!product) {
      errorMessage.push("Please fill in a product.");
    }
    if (errorMessage.length > 0) {
      setError(
        <div>
          {errorMessage.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </div>
      );
      return;
    }

    await post(`/api/customer/${customer.id}/merch`, {
      brand,
      category,
      product,
    });
    if (response.ok) {
      fetchMerch();
      setError(null);
    } else {
      setError("Error updating merch.");
    }
  };

  const handleCreateDashboard = async () => {
    const errorMessage = [];
    if (!dashboardName) {
      errorMessage.push("Please enter a `Dashaboard Name` for your dashboard.");
    }
    if (!startDate) {
      errorMessage.push("Please set `Campaign Start Date`.");
    }
    if (!endDate) {
      errorMessage.push("Please set `Campaign End Date`.");
    }
    if (!formJobCode) {
      // setError("Please set `job_code`.");
      errorMessage.push("Please set `Job Number`.");
    }
    if (!formName) {
      errorMessage.push("Please set `Form Name`.");
    }
    if (selectedLocations.length === 0) {
      errorMessage.push("Please select at least one location; you can create new if needed.");
    }
    if (selectedMerch.length === 0) {
      errorMessage.push("Please select at least one brand/product; you can create new if needed.");
    }

    if (errorMessage.length > 0) {
      setError(
        <div>
          {errorMessage.map((message, index) => (
            <div key={index}>{message}</div>
          ))}
        </div>
      );
      return;
    }
    setIsLoading(true);
    let data = await post(`/api/customer/${customer.id}/dashboards`, {
      title: dashboardName,
      category: dashboardCategory,
      start_date: startDate,
      end_date: endDate,
      locations: selectedLocations.join(","),
      merch: selectedMerch.join(","),
      form_title: formTitle,
    });
    setIsLoading(false);
    if (response.ok) {
      setError(null);
      navigate(`/dashboard-reporting-selection/${data.id}`);
    } else {
      //TODO: something does not work
      setError("Unexpected error creating dashboard.");
    }
  };

  const _locationFilter = (location) => {
    return (
      location.country.includes(countryFilter) &&
      location.region.includes(regionFilter) &&
      location.city.includes(cityFilter) &&
      location.location.includes(locationFilter)
    );
  };
  const _merchFilter = (merch) => {
    return (
      merch.brand.includes(brandFilter) &&
      merch.category.includes(categoryFilter) &&
      merch.product.includes(productFilter)
    );
  };

  return (
    <Col
      xs={8}
      md={6}
      lg={6}
      xl={6}
      className={isLoading ? "loading-cursor mx-auto" : "mx-auto"}
    >
      <br />
      <Row className="mx-auto">
        <h1 className="text-light">
          Create Dashboard for: <b>{customer.title}</b>
        </h1>
      </Row>
      <br />
      {error && <p className="text-danger">{error}</p>}
      <Form>
        <Row className="mx-auto">
          <Col>
            <Form.Group className="mb-3" controlId="dashboardCreate.name">
              <Form.Label className="text-light">Dashboard Name *</Form.Label>
              <Form.Control
                onChange={(e) => setDashboardName(e.target.value)}
                maxLength={80}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="dashboardCreate.category">
              <Form.Label className="text-light">Category *</Form.Label>
              <Form.Select
                onChange={(e) => {
                  setDashboardCategory(e.target.value);
                }}
              >
                <option value={"bronze"}>Starter</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mx-auto">
          <Col>
            <Form.Group className="mb-3" controlId="dashboardCreate.startDate">
              <Form.Label className="text-light">
                Campaign Start Date *
              </Form.Label>
              <Form.Control
                type="date"
                dateformat="YYYY-MM-DD"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3" controlId="dashboardCreate.endDate">
              <Form.Label className="text-light">
                Campaign End Date *
              </Form.Label>
              <Form.Control
                type="date"
                dateformat="YYYY-MM-DD"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mx-auto">
          <Col className="mx-auto">
            <Card>
              <CardHeader>Form Title:</CardHeader>
              <Card.Body>
                <Row className="mx-auto">
                  <Col key="formType">
                    <Form.Group
                      className="mb-3"
                      controlId="dashboardCreate.formType"
                    >
                      <Form.Label className="text-black">Form Type</Form.Label>
                      <Form.Select
                        onChange={(e) => {
                          setFormType(e.target.value);
                        }}
                      >
                        <option>EOS</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col key="formDb">
                    <Form.Group
                      className="mb-3"
                      controlId="dashboardCreate.formDb"
                    >
                      <Form.Label className="text-black">Project DB</Form.Label>
                      <Form.Select
                        onChange={(e) => {
                          setFormDb(e.target.value);
                        }}
                      >
                        <option value={"UK"}>UK</option>
                        <option value={"DE"}>DE</option>
                        <option value={"FR"}>FR</option>
                        <option value={"US"}>US</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>

                  <Col key="formJobCode">
                    <Form.Group
                      className="mb-3"
                      controlId="dashboardCreate.formJobCode"
                    >
                      <Form.Label className="text-black">
                        Job Number
                      </Form.Label>
                      <Form.Control
                        onChange={handleJobCodeChange}
                        placeholder="eg. EXP01001"
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col key="formName">
                    <Form.Group
                      className="mb-3"
                      controlId="dashboardCreate.formName"
                    >
                      <Form.Label className="text-black">Form Name</Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          setFormName(e.target.value);
                        }}
                        placeholder="eg. My Form Name"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="text-center">{formTitle}</Card.Footer>
            </Card>
          </Col>
        </Row>
      </Form>

      <br />
      <br />
      <Row className="mx-auto">
        <Col xs={8}>
          <h3 className="text-light">Select Locations</h3>
        </Col>
      </Row>
      <Row className="mx-auto">
        <div style={{ maxHeight: "350px", overflowY: "scroll" }}>
          <Table hover className="rounded-3 overflow-hidden">
            <thead>
              <tr className="border-0">
                <th>
                  <Form.Control
                    placeholder="Country"
                    type="country"
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    placeholder="Region"
                    type="region"
                    onChange={(e) => setRegion(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    placeholder="City"
                    type="city"
                    onChange={(e) => setCity(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    placeholder="Location"
                    type="location"
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </th>
                <th></th>
                <th>
                  <Button className="w-100" onClick={() => handleLocationCreateSubmit(true)}>Create</Button>
                </th>
              </tr>
              <tr className="border-0">
                <th>
                  <Form.Control
                    placeholder="Country"
                    type="country"
                    onChange={(e) => setCountryFilter(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    placeholder="Region"
                    type="region"
                    onChange={(e) => setRegionFilter(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    placeholder="City"
                    type="city"
                    onChange={(e) => setCityFilter(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    placeholder="Location"
                    type="location"
                    onChange={(e) => setLocationFilter(e.target.value)}
                  />
                </th>
                <th style={{ textAlign: 'center', }}>
                  <Form.Check
                    defaultChecked={true}
                    onChange={(e) => {
                      let _selectedLocations = [];
                      if (e.target.checked) {
                        locations.forEach((location) => {
                          _selectedLocations.push(location.id);
                        });
                      }
                      setSelectedLocations(_selectedLocations);
                    }}
                  />
                </th>
                <th><Button disabled className="bg-grey border-grey w-100">Search</Button></th>
              </tr>
            </thead>
            <tbody>
              {locations && locations.length === 0 && <p>No locations found</p>}
              {locations &&
                locations
                  .filter((location) => _locationFilter(location))
                  .map((location) => (
                    <tr className="border-0">
                      <td>{location.country}</td>
                      <td>{location.region}</td>
                      <td>{location.city}</td>
                      <td>{location.location}</td>
                      <td style={{ textAlign: 'center', }}>
                        <Form.Check
                          checked={selectedLocations.includes(location.id)}
                          onChange={(e) => {
                            let _selectedLocations = selectedLocations.filter(
                              (id) => id !== location.id,
                            );
                            if (e.target.checked) {
                              _selectedLocations.push(location.id);
                            }
                            setSelectedLocations(_selectedLocations);
                          }}
                        />
                      </td>
                      <td></td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </div>
      </Row>
      <br />
      <br />
      <Row className="mx-auto">
        <Col xs={8}>
          <h3 className="text-light">Select Brands & Products</h3>
        </Col>
      </Row>
      <Row className="mx-auto">
        <div style={{ maxHeight: "350px", overflowY: "scroll" }}>
          <Table hover className="rounded-3 overflow-hidden">
            <thead>
              <tr className="border-0">
                <th>
                  <Form.Control
                    placeholder="Brand"
                    type="brand"
                    onChange={(e) => setBrand(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    placeholder="Category"
                    type="category"
                    onChange={(e) => setCategory(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    placeholder="Product"
                    type="product"
                    onChange={(e) => setProduct(e.target.value)}
                  />
                </th>
                <th></th>
                <th>
                  <Button className="w-100" onClick={() => handleMerchCreateSubmit(true)}>Create</Button>
                </th>
              </tr>

              <tr className="border-0">
                <th>
                  <Form.Control
                    placeholder="Brand"
                    type="brand"
                    onChange={(e) => setBrandFilter(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    placeholder="Category"
                    type="category"
                    onChange={(e) => setCategoryFilter(e.target.value)}
                  />
                </th>
                <th>
                  <Form.Control
                    placeholder="Product"
                    type="product"
                    onChange={(e) => setProductFilter(e.target.value)}
                  />
                </th>
                <th style={{ textAlign: 'center', }}>
                  <Form.Check
                    defaultChecked={true}
                    onChange={(e) => {
                      let _selectedMerch = [];
                      if (e.target.checked) {
                        merch.forEach((mrch) => {
                          _selectedMerch.push(mrch.id);
                        });
                      }
                      setSelectedMerch(_selectedMerch);
                    }}
                  />
                </th>
                <th>
                  <Button disabled className="bg-grey border-grey w-100">Search</Button>
                </th>
              </tr>
            </thead>
            <tbody>
              {merch && merch.length === 0 && <p>No products found</p>}
              {merch &&
                merch
                  .filter((mrch) => _merchFilter(mrch))
                  .map((mrch) => (
                    <tr className="border-0">
                      <td>{mrch.brand}</td>
                      <td>{mrch.category}</td>
                      <td>{mrch.product}</td>
                      <td style={{ textAlign: 'center', }}>
                        <Form.Check
                          checked={selectedMerch.includes(mrch.id)}
                          onChange={(e) => {
                            let _selectedMerch = selectedMerch.filter(
                              (id) => id !== mrch.id,
                            );
                            if (e.target.checked) {
                              _selectedMerch.push(mrch.id);
                            }
                            setSelectedMerch(_selectedMerch);
                          }}
                        />
                      </td>
                      <td></td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </div>
      </Row>
      <br />
      {error && <p className="text-danger">{error}</p>}
      <br />
      <Row className="mx-auto">
        <Col key="createButton">
          {isLoading ? (
            <Button className="greyed-out">Creating...</Button>
          ) : (
            <Button onClick={() => handleCreateDashboard()}>
              Create dashboard
            </Button>
          )}
        </Col>
      </Row>
      <br />
    </Col>
  );
}
