import React, { useState, useContext } from "react";
import { Form, Button, Container, Alert, Col, Row } from "react-bootstrap";
import { MainContext } from "../../Providers/MainContext";
import useFetch from "use-http";
import { useTranslation } from "react-i18next";
import { emailRegex, passwordRegex } from "../../Utils/Regex";

const RegistrationPage = () => {
  const [formData, setFormData] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);

  const [formErrors, setFormErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const { setJwt } = useContext(MainContext);
  const { post, response } = useFetch(process.env.REACT_APP_API_URL, {
    cachePolicy: "no-cache",
  });
  const { t } = useTranslation();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // Optionally reset errors
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const validate = () => {
    const newErrors = {};

    // Username validation
    if (!formData.username) {
      newErrors.username = "Username is required";
    }
    // First name validation
    if (!formData.first_name) {
      newErrors.first_name = "First name is required";
    }
    // Last name validation
    if (!formData.last_name) {
      newErrors.last_name = "Last name is required";
    }
    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    // Password validation
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        "Password must include at least one lowercase letter, one uppercase letter, one digit, one of !@#$%^&*, and be at least 8 characters long.";
    }

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (!validate()) {
      e.stopPropagation();
      setValidated(false); // Set validated to False to trigger Bootstrap's validation styles
      return; // Stop submission since there are errors
    }

    let data = await post("/api/registration/", formData);

    if (!response.ok) {
      if (response.data.username) {
        setFormErrors({ ...formErrors, username: response.data.username[0] });
        return;
      }

      return;
    }

    setError(null);
    setJwt(data.access, {
      days: 1,
      SameSite: "Strict",
      Secure: true,
    });
  };

  return (
    <Container className="mt-5">
      {/* make the h2 middle */}

      <Row>
        <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
          <h2 className="text-white">{t("Registration")}</h2>
        </Col>
      </Row>
      <Form noValidate onSubmit={handleSubmit}>
        {/* Username */}
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Form.Group className="mb-3">
              <Form.Label className="text-white">{t("Username")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Username")}
                name="username"
                value={formData.username}
                onChange={handleChange}
                isInvalid={!!formErrors.username}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.username}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {/* First Name */}
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Form.Group className="mb-3">
              <Form.Label className="text-white">{t("First Name")} </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("First Name")}
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                isInvalid={!!formErrors.first_name}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.first_name}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            {/* Last Name */}
            <Form.Group className="mb-3">
              <Form.Label className="text-white">{t("Last Name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Last Name")}
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                isInvalid={!!formErrors.last_name}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.last_name}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/* Email */}
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Form.Group className="mb-3">
              <Form.Label className="text-white">{t("Email")}</Form.Label>
              <Form.Control
                type="email"
                placeholder={t("Email")}
                name="email"
                value={formData.email}
                onChange={handleChange}
                isInvalid={!!formErrors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.email}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/* Password */}
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Form.Group className="mb-3">
              <Form.Label className="text-white">{t("Create Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("Password")}
                name="password"
                value={formData.password}
                onChange={handleChange}
                isInvalid={!!formErrors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.password}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        {/* Submit Button */}
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Button variant="primary" type="submit">
              {t("Sign Up")}
            </Button>
          </Col>
        </Row>
        {/* Error */}
        {error && <Alert variant="danger">{error}</Alert>}
      </Form>
    </Container>
  );
};

export default RegistrationPage;
