import OverlayCategoricalSingleChart from "./OverlayCategoricalSingleChart";
import OverlayCategoricalGroupChart from "./OverlayCategoricalGroupChart";
import ImpactTable from "../Table/ImpactTable";

export default function PieTable({
  data,
  overlay,
  groupBy,
  dropdownSelection,
}) {
  function processPlotData(data) {
    let plotData = JSON.parse(JSON.stringify(data.value));
    Object.keys(plotData).forEach(key => {
        plotData[key] = plotData[key]["plot"];
    });
    return plotData;
  }
  function extractAndMerge(data) {
    let mergedList = [];
    Object.keys(data.value).forEach(key => {
        mergedList = mergedList.concat(data.value[key]["table"]);
    });
    return mergedList;
  }

  const plotData = {"name": data.name, "type": data.type, "value": data.value.plot}
  const tableData = {"name": data.name, "type": data.type, "value": data.value.table}
  if (groupBy) {
    plotData["value"] = processPlotData(data);
    tableData["value"] = extractAndMerge(data);
  }

  const renderPlot = () => {
    if (groupBy) {
      return (
        <OverlayCategoricalGroupChart
          chartData={plotData}
          overlay={overlay}
          dropdownSelection={dropdownSelection}
        />
      )
    } else {
      return (
        <OverlayCategoricalSingleChart
          chartData={plotData}
          overlay={overlay}
          dropdownSelection={dropdownSelection}
        />
      );
    }
  }

  return (
    <>
      {renderPlot()}
      <ImpactTable chartData={tableData} />
    </>
  )

}
    
