import React from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import {
  handleRemoveFilterGroup,
  useSwitchableSelectionState,
} from "../Utils/FilterGroupUtils";
import useFilterStore from '../Hooks/useFilterStore';

const BadgeList = ({ items, label, onRemove, t }) => {
  // items are set
  items = Array.from(items);
  return (
    <>
      {items
        &&
        items.map((item) => (
          <div
            key={label + "-" + item + "-badge"}
            onClick={() => onRemove(item)}
            style={{ display: 'inline', padding: '3px' }}
          >
            <Badge bg="secondary">
              {t(label)}: {item} <X />
            </Badge>
          </div>
        ))
      }
    </>
  );
};

const FilterBadges = ({
  filterParams, // This will be passed down from the parent.
  t, // Translation function
  displayFunc = (x) => x
}) => {
  const {
    startdate,
    endDate,
    selectedCountries,
    selectedRegions,
    selectedCities,
    selectedLocations,
    selectedBrands,
    selectedCategories,
    selectedProducts,
    selectedFilterGroups,
    setStartDate,
    setEndDate,
    setSelectedCountries,
    setSelectedRegions,
    setSelectedCities,
    setSelectedLocations,
    setSelectedBrands,
    setSelectedCategories,
    setSelectedProducts,
    setSelectedFilterGroups,
    setFilterGroupSwitchStates,
  } = useFilterStore();

  const { handleRemoveItem: handleRemoveCountry } = useSwitchableSelectionState(selectedCountries, setSelectedCountries);
  const { handleRemoveItem: handleRemoveRegion } = useSwitchableSelectionState(selectedRegions, setSelectedRegions);
  const { handleRemoveItem: handleRemoveCity } = useSwitchableSelectionState(selectedCities, setSelectedCities);
  const { handleRemoveItem: handleRemoveLocation } = useSwitchableSelectionState(selectedLocations, setSelectedLocations);
  const { handleRemoveItem: handleRemoveBrand } = useSwitchableSelectionState(selectedBrands, setSelectedBrands);
  const { handleRemoveItem: handleRemoveCategory } = useSwitchableSelectionState(selectedCategories, setSelectedCategories,);
  const { handleRemoveItem: handleRemoveProduct } = useSwitchableSelectionState(selectedProducts, setSelectedProducts);

  const onRemoveGroup = (value, label) => {
    handleRemoveFilterGroup(
      value,
      label,
      setSelectedFilterGroups,
      setFilterGroupSwitchStates,
    );
  };

  const excludedFilterGroupBadges = [
    "job_id",
    "db",
    "freelancer_id",
    "consecutive_days",
    "last_day",
  ];

  const removeDateRangeBadge = () => {
    setStartDate(null);
    setEndDate(null);
  }
  const displayDateRange = () => {
    let badgeText = "";
    if (startdate && endDate) {
      if (startdate === endDate) {
        badgeText = startdate;
      } else {
        badgeText = `${startdate} -> ${endDate}`;
      }
    } else if (startdate) {
      badgeText = `${startdate} -> default endDate`
    } else if (endDate) {
      badgeText = `default startDate -> ${endDate}`
    }
    if (badgeText) {
      return (
        <div
          key={`start-and-end-badge`} // Use a unique key
          onClick={removeDateRangeBadge}
          style={{ display: 'inline', padding: '3px' }}
        >
          <Badge bg="secondary">
            {t("Date Range")}: {badgeText} <X />
          </Badge>
        </div>
      )
    }
    return
  }
  return (
    <Row className="mx-auto align-items-start">
      <Col>
        {displayDateRange()}
        <BadgeList
          items={selectedCountries}
          label="Country"
          onRemove={handleRemoveCountry}
          t={t}
        />
        <BadgeList
          items={selectedRegions}
          label="Region"
          onRemove={handleRemoveRegion}
          t={t}
        />
        <BadgeList
          items={selectedCities}
          label="City"
          onRemove={handleRemoveCity}
          t={t}
        />
        <BadgeList
          items={selectedLocations}
          label="Location"
          onRemove={handleRemoveLocation}
          t={t}
        />
        <BadgeList
          items={selectedBrands}
          label="Brand"
          onRemove={handleRemoveBrand}
          t={t}
        />
        <BadgeList
          items={selectedCategories}
          label="Category"
          onRemove={handleRemoveCategory}
          t={t}
        />
        <BadgeList
          items={selectedProducts}
          label="Product"
          onRemove={handleRemoveProduct}
          t={t}
        />
        {selectedFilterGroups &&
          Object.entries(selectedFilterGroups).map(([label, values]) =>
            values.map(
              (value) =>
                !excludedFilterGroupBadges.includes(label) && (
                  <div
                    key={`${label}-${value}`} // Use a unique key
                    onClick={() => onRemoveGroup(value, label)}
                    style={{ display: 'inline', padding: '3px' }}
                  >
                    <Badge bg="secondary">
                      {t(label)}: {displayFunc(value)} <X />
                    </Badge>
                  </div>
                ),
            ),
          )}
      </Col>
    </Row>
  );
};

export default FilterBadges;
