import React from "react";
import { WordcloudWithinGroup } from "./WordcloudWithinGroup";
import { Col, Container, Row } from "react-bootstrap";


// GroupedWordCloud component
export default function GroupedWordCloud(props) {
  const { chartData } = props;


  return (
    <Container >
      <Row>
        {chartData.value && Object.entries(chartData.value).map(([label, dcount], index) => (
          <Col md={12} key={index} className="mb-5 mt-5">
            {/* Pass the individual dcount and label for each word cloud */}
            <WordcloudWithinGroup chartData={dcount} label={label} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}
