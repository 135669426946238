import { useState } from "react";

export default function useOverlay() {
  const [overlayOptions, setOverlayOptions] = useState({});
  const [overlaySelection, setOverlaySelection] = useState("");
  const [overlayData, setOverlayData] = useState(null);

  return {
    overlayOptions,
    overlaySelection,
    overlayData,
    setOverlayOptions,
    setOverlaySelection,
    setOverlayData,
  };
}
