import React, { useMemo, useState } from 'react';
import {Row, Col} from 'react-bootstrap';
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    getPaginationRowModel,
    flexRender,
} from '@tanstack/react-table';
import { firstLetterCaps } from '../../../Utils/utilFunctions';

const TableHeader = ({ headerGroups }) => (
    <thead>
        {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                    <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        style={{
                            borderBottom: 'solid 3px black',
                            background: 'white',
                            color: 'black',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                        }}
                    >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <span>
                            {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                            }[header.column.getIsSorted()] ?? ''}
                        </span>
                    </th>
                ))}
            </tr>
        ))}
    </thead>
);

const TableBody = ({ rows }) => (
    <tbody>
        {rows.map(row => (
            <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                    <td
                        key={cell.id}
                        style={{
                            padding: '10px',
                            border: 'solid 1px black',
                            background: 'white',
                        }}
                    >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                ))}
            </tr>
        ))}
    </tbody>
);

const Pagination = ({ table }) => (
    <div className="pagination">
        <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
        >
            {'<<'}
        </button>
        <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
        >
            {'<'}
        </button>
        <span>
            Page{' '}
            <strong>
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
            </strong>
        </span>
        <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
        >
            {'>'}
        </button>
        <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
        >
            {'>>'}
        </button>
        <select
            value={table.getState().pagination.pageSize}
            onChange={e => {
                table.setPageSize(Number(e.target.value));
            }}
        >
            {[10, 20, 100, 200].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                </option>
            ))}
        </select>
    </div>
);

// Custom Table Hook with Search
const useCustomTable = (chartData, searchQuery) => {
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    // Filter data based on the search query
    const filteredData = useMemo(() => {
        if (!chartData || !chartData.value) return [];
        if (!searchQuery) return chartData.value;

        return chartData.value.filter(row =>
            Object.values(row).some(
                val => String(val).toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [chartData, searchQuery]);

    const columns = useMemo(() => {
        if (!chartData || !chartData.value || chartData.value === "No data." || chartData.value.length === 0) return [];
        
        // Create the dynamic columns based on the keys of chartData.value[0]
        const dynamicColumns = Object.keys(chartData.value[0] || {}).map((key) => ({
            header: firstLetterCaps(key),
            accessorKey: key,
        }));

        // Add the row number column
        return [
            {
                header: '#',
                accessorFn: (_, index) => index + 1,
                id: 'rowNumber',
            },
            ...dynamicColumns,
        ];
    }, [chartData]);

    const table = useReactTable({
        columns,
        data: filteredData || [],
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        state: {
            pagination,
        },
    });

    return table;
};

const ImpactTable = ({ chartData }) => {
    const [searchQuery, setSearchQuery] = useState('');  // Search query state
    const table = useCustomTable(chartData, searchQuery);  // Pass search query to table hook

    return (
        <>
            <Row>
              <Col className="text-end" >
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search..."
                className="w-25 rounded m-1"
                // style={{ marginBottom: '10px', padding: '5px' }}
              />
              </Col>
            </Row>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <TableHeader headerGroups={table.getHeaderGroups()} />
                <TableBody rows={table.getRowModel().rows} />
            </table>
            <Pagination table={table} />
        </>
    );
};

export default ImpactTable;
