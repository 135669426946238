import { graphColorsJSON } from "../../../Utils/GraphColors";
import { GenericGaugeSingleChart } from "./GenericGaugeSingleChart"
import { extractValuesFromJSON } from "../../../Utils/utilFunctions";

const gaugeConditions = ['Very Bad', 'Bad', 'Normal', 'Good', 'Excellent'];
const gaugeRangeLabels = [' 1 to <2', ' 2 to <3', '3 to <3.8', '3.8 to <4.5', '4.5 to <5'];
const gaugeColors = extractValuesFromJSON(graphColorsJSON, ["danger", "purple", "blue", "cyan", "primary-teal"])
const gaugeRanges = [1, 1, 0.8, 0.7, 0.5];
const gaugeRotation = (context) => {
    switch(context.dataIndex) {
        case 0: return -65;
        case 1: return -25;
        case 2: return 15;
        case 3: return 50;
        case 4: return 80;
        default: return 0;  // Optional default case
    }
}

export const GaugeSingleChart = (props) => (
    <GenericGaugeSingleChart {...props}
        gaugeConditions={gaugeConditions}
        gaugeRangeLabels={gaugeRangeLabels}
        gaugeColors={gaugeColors}
        gaugeRanges={gaugeRanges}
        startValue={1}
        metricTitle="Event Capacity Score"
        rotationFunction={gaugeRotation}
    />);

