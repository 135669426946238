import { useEffect, useRef } from "react";
import { Chart } from "react-chartjs-2";
import { getColor } from "../../../Utils/GraphColorPicker";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  chartJsDefaultOptions,
  createCategoricalLabels,
} from "../../../Utils/chartJsOptions";
import zoomPlugin from "chartjs-plugin-zoom";

// Accepts Chart Data as Returned by the API and renders a chart
// This class should be the only thing that changes if the api output does
export default function CategoricalGroupBarChart(props) {
  const { chartData, dropdownSelection } = props;

  const chartRef = useRef(null);
  // Trigger chart update when chartData changes
  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.update();
    }
  }, [chartData]);

  if (chartData.value === undefined) return <></>;

  const labels = Object.keys(chartData.value);
  const options = {
    ...chartJsDefaultOptions,
    x: { stacked: true, min: 0 },
    y: {
      stacked: true,
      // if dropdown menu is percentage, y axis max is 100
      // if dropdown menu is count, y axis is the sum of chartData.value
      max: dropdownSelection === "percentage" ? 100 : undefined,
      // chartData.value.reduce(
      //   (accumulator, currentValue) => {
      //   return accumulator + currentValue;
      // }, 0)
    },
    options: {
      animation: labels.length > 8 ? false : true,
      snapGaps: labels.length > 8 ? true : false,
      normalized: true,
      parsing: false,
      ticks: {
        beginAtZero: true,
        sampleSize: 5,
        minRotation: 45,
        maxRotation: 45,
      },
    },
  };
  options.plugins.datalabels["labels"] =
    createCategoricalLabels(dropdownSelection);

  const isArrayLike = (obj) => {
    const keys = Object.keys(obj);
    return keys.every((key, index) => Number(key) === index);
  };
  if (isArrayLike(chartData.value)) return;

  let uniqueOptions = [];
  let useTooltip = false;
  Object.keys(chartData.value).forEach((key) => {
    chartData.value[key].forEach((value) => {
      if (!uniqueOptions.includes(value.value)) {
        uniqueOptions.push(value.value);
      }
      if (!useTooltip && value.tooltip) useTooltip = true;
    });
  });

  if (useTooltip) {
    // Only for shift_status right now.

    // Consistent order of regions.
    const customOrder = {
      US: 0,
      UK: 1,
      Germany: 2,
      France: 3,
      Austria: 4,
    };
    uniqueOptions.sort((a, b) => {
      // Check if both `a` and `b` are in `customOrder`
      const orderA = customOrder.hasOwnProperty(a) ? customOrder[a] : Infinity;
      const orderB = customOrder.hasOwnProperty(b) ? customOrder[b] : Infinity;
      // Sort based on `customOrder` values, with non-specified items at the end
      return orderA - orderB;
    });

    options.plugins["tooltip"] = {
      mode: "index",  // Show tooltips for all stacks in a bar
      intersect: false,  // Show tooltip even if not hovering over a specific stack
      reverse: true,  // Same vertical order as bars.
      callbacks: {
        label: function (context) {
          if (context.dataset.tooltip[context.dataIndex]) {
            return `${context.dataset.label}: ${context.dataset.tooltip[context.dataIndex]}`;
          } else {
            return "";
          }
        },
      },
    };
  }

  // items is chartData.value[groupingKey]
  Object.entries(chartData.value).forEach(([groupingKey, items]) => {
    // Calculate total dcount for the grouping
    let totalDcount = items.reduce((total, item) => total + item.dcount, 0);
    // Calculate percentage and add perc key for each item
    chartData.value[groupingKey] = items.map((item) => {
      // count
      if (dropdownSelection === "count") return { ...item, perc: item.dcount };
      // add more options here

      // default is percentage
      let percent = (100 * item.dcount) / totalDcount;
      return { ...item, perc: percent };
    });
  });

  const dataSets = uniqueOptions.map((option, index) => {
    let data = [];
    let count = [];
    let tooltip = [];
    Object.keys(chartData.value).forEach((key) => {
      let value = chartData.value[key].find((item) => item.value === option);
      if (value) {
        data.push(value.perc || 0);
        count.push(value.dcount || 0);
        tooltip.push(value.tooltip || "");
      } else {
        // If the option is not found in this region, push 0 to both arrays
        data.push(0);
        count.push(0);
        tooltip.push("");
      }
    });
    return {
      label: option,
      data: data,
      count: count,
      tooltip: tooltip,
      backgroundColor: getColor(index),
    };
  });

  const chartJsSetup = {
    labels: labels,
    datasets: dataSets,
  };

  return (
    <Chart
      ref={chartRef}
      type="bar"
      options={options}
      data={chartJsSetup}
      plugins={[ChartDataLabels, zoomPlugin]}
    />
  );
}
