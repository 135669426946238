import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useFetchConfig from "../Hooks/useFetchConfig";
import useFetch from "use-http";
import Form from "react-bootstrap/Form";
import CustomerUserListTable from "./User/CustomerUserListTable";
import { useTranslation } from "react-i18next";

import { MainContext } from "../Providers/MainContext";

export default function AdminSettingsPage() {
  const { customer, setCustomer } = useContext(MainContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const fetchConfig = useFetchConfig();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const {
    patch,
    post,
    delete: deleter,
    response,
  } = useFetch(process.env.REACT_APP_API_URL, fetchConfig);

  useEffect(() => {
    setTitle(customer.title);
    setDescription(customer.description);
  }, [customer]);

  const _handleAddUser = async (userId) => {
    let data = await post(`/api/customer/admins/${userId}`);
    if (response.ok) {
      setError(null);
      setCustomer(data);
    }
  };

  const _handleRemoveUser = async (userId) => {
    await deleter(`/api/customer/admins/${userId}`);
    if (response.ok) {
      setError(null);
      customer.admins = customer.admins.filter((user) => user.id !== userId);
      setCustomer(customer);
    }
  };

  return (
    <Col xs={8} md={6} lg={6} xl={6} className="mx-auto">
      <br />
      <h1 className="text-light">{"Admin Settings"}</h1>
      <br />
      <Row>{error && <p className="text-danger">{error}</p>}</Row>
      {customer && customer.admins && (
        <Row>
          <Col xs={8} md={8} lg={8} xl={8} className="mx-auto">
            <Form>
              <Form.Group className="mb-3" controlId="customerEdit.title">
                <Form.Label className="text-light">{t("Title")}</Form.Label>
                <Form.Control
                  className=""
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="customerEdit.description"
              >
                <Form.Label className="text-light">
                  {t("Description")}
                </Form.Label>
                <Form.Control
                  className=""
                  as="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              <Button
                onClick={async () => {
                  await patch(`/api/customer`, {
                    title: title,
                    description: description,
                  });
                  if (response.ok) {
                    setError(null);
                    navigate(`/`);
                  }
                }}
              >
                {t("Save")}
              </Button>
            </Form>
            <CustomerUserListTable
              title={"Admins"}
              selectedUserIds={customer.admins.map((user) => user.id)}
              handleAddUser={(userId) => _handleAddUser(userId)}
              handleRemoveUser={(userId) => _handleRemoveUser(userId)}
            />
          </Col>
        </Row>
      )}
    </Col>
  );
}
