import { useState } from "react";
import { CardHeader, Card, Form, Col, Button, InputGroup, FormControl } from "react-bootstrap";
import handleStyle from "./handleStyle";

export default function SeparateInputsCard({
  labelName,
  finalState,
  externalState,
  setExternalState,
  cardHeader,
  controlId,
  label,
  checkShort=true,
  disabled=false,
}) {

  const [internalError, setInternalError] = useState("");
  const handleAddLabelField = () => {
    setExternalState([...externalState, ""]); // Add new empty string to array
  };
  const handleRemoveLabelField = (index) => {
    setExternalState((externalState) =>
      externalState.filter((_, i) => i !== index),
    ); // Remove string from array
  };
  const filterAndSetError = (value, name, setErrorFunction) => {
    let filteredValue = value;
    if (checkShort) {
      filteredValue = handleStyle(value);
    }
    if ((filteredValue.length < 6) && (checkShort)) {
      setErrorFunction(
        `If your ${name} is too short, it'll be hard for future ${name}s to be distinct. Make it longer.`,
      );
    } else if (filteredValue.length > 63) {
      setErrorFunction(
        `If your ${name} is too long, it won't fit in the database field.`,
      );
    } else if (filteredValue.endsWith("_")) {
      setErrorFunction(`End your ${name} with a letter.`);
    } else {
      setErrorFunction("");
    }
    return filteredValue
  };
  const handleLabelInputChange = (index, event) => {
    const filteredValue = filterAndSetError(
      event.target.value,
      labelName,
      setInternalError,
    );
    const values = [...externalState];
    values[index] = filteredValue;
    setExternalState(values);  
  };

  return (
    <Col>
      <Card className="h-100">
        <CardHeader>{cardHeader}</CardHeader>
        <Card.Body className="flex-grow-1 d-flex flex-column">
          <Form.Group
            controlId={controlId}
            className="flex-grow-1 d-flex flex-column"
          >
            <Form.Label>{label}</Form.Label>
            <Button
              variant="primary"
              onClick={handleAddLabelField}
              className="mt-auto mb-3"
              disabled={disabled}
            >
              Add More
            </Button>
            {externalState.map((input, index) => (
              <InputGroup className="mb-3" key={index}>
                <FormControl
                  placeholder="Extra labels if required."
                  value={input}
                  onChange={(event) =>
                    handleLabelInputChange(index, event)
                  }
                  disabled={disabled}
                />
                <Button
                  variant="danger"
                  onClick={() => handleRemoveLabelField(index)}
                  disabled={disabled}
                >
                  Remove
                </Button>
              </InputGroup>
            ))}
          </Form.Group>
        </Card.Body>
        <Card.Footer className="text-center">
          {internalError && <p className="text-danger">{internalError}</p>}
          {finalState}
        </Card.Footer>
      </Card>
    </Col>
  );
}
