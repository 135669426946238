import React, { useRef, useEffect } from "react";
import { Col } from "react-bootstrap";


// Your FilterInput component
const FilterInput = ({ label, type = "text", value, onChange }) => {
  const inputRef = useRef(null); // Ref for the input element

  // Focus the input field after rerender
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Refocus the input
    }
  }, [value]); // This ensures focus is applied after every render

  return (
    <Col>
      <label>{label}</label>
      <input
        type="text"
        className="form-control bg-light"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        ref={inputRef} // Attach the ref to the input element
      />
    </Col>
  );
};

export default FilterInput;
