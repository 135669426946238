import { graphColorsJSON, blackColor } from "./GraphColors";

// Utility function to check if an object is not empty
function isObjectNotEmpty(obj) {
  return obj && Object.keys(obj).length > 0;
}

function sortArrayByDcountAndValue(array) {
  return array.sort((a, b) => {
    // Primary sort based on dcount (descending)
    const dcountDifference = b.dcount - a.dcount;
    if (dcountDifference !== 0) return dcountDifference;

    // Secondary sort based on value (alphabetically ascending)
    return a.value.localeCompare(b.value);
  });
}

function firstLetterCaps(str) {
  return str
    .toLowerCase() // Convert the whole string to lowercase
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/^\w/, c => c.toUpperCase()); // Capitalize the first letter of the string
}

function countDigits(number) {
  // Convert number to string and replace '.', then get the length
  return Math.abs(number).toString().replace('.', '').length;
}

function findMaxDigits(arr) {
  return Math.max(...arr.map(number => countDigits(number)));
}

function arraysToObject(keys, values) {
  let result = {};
  for (let i = 0; i < keys.length; i++) {
    result[keys[i]] = values[i];
  }
  return result;
}

function concatenateArrays(arr1, arr2, joinStr = ': ') {
  let result = [];
  for (let i = 0; i < arr1.length; i++) {
    result.push(`${arr1[i]}${joinStr}${arr2[i]}`);
  }
  return result;
}

function extractValuesFromJSON(jsonObj, keys) {
  let values = [];
  for (let key of keys) {
    if (jsonObj.hasOwnProperty(key)) {
      values.push(jsonObj[key]);
    } else {
      values.push(undefined);
    }
  }
  return values;
}

function dbToLocale(db) {
  switch (db) {
    case "DE":
      return "de-DE";
    case "FR":
      return "fr-FR";
    case "UK":
      return "en-UK";
    case "US":
      return "en-US";
    default:
      return "en-CA";
  }
}
const formatTimestamp = (tsString, locale) => {
  if (!tsString) return;
  // const locale = navigator.language; // Auto-detects locale
  const date = new Date(tsString);
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric', month: 'numeric', day: 'numeric',
    hour: '2-digit', minute: '2-digit', second: '2-digit'
  }).format(date);
};

function formatStdDateLocaleTime(tsString, locale = 'de-DE') {
  if (!tsString) return;
  try {
    const date = new Date(tsString);
    // Format the date in YYYY-MM-DD format

    const dateFormatter = new Intl.DateTimeFormat('en-CA', { // 'en-CA' uses the YYYY-MM-DD format natively
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
    // Format the time according to the user's locale
    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
    return `${dateFormatter.format(date)} ${timeFormatter.format(date)}`;

  } catch (error) {
    return tsString;
  }

}

function formatTimeToLocale(timeString, locale = 'de-DE') {
  if (!timeString) return;
  
  try {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':');
    
    // Create a new Date object with today's date, and the provided time
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds || 0); // Default to 0 if seconds are not provided
    const use12Hour = uses12HourClock(locale);

    // Format the time according to the user's locale
    const timeFormatter = new Intl.DateTimeFormat(locale, {
      hour: '2-digit',
      minute: '2-digit',
      second: seconds ? '2-digit' : undefined, // Include seconds only if present
      hour12: use12Hour,
    });
    return timeFormatter.format(date);

  } catch (error) {
    return timeString; // Return the original string if any error occurs
  }
}

function uses12HourClock(locale = 'en-US') {
  const date = new Date();
  
  const formattedTime = new Intl.DateTimeFormat(locale, {
    hour: 'numeric'
  }).format(date);
  
  // If the formatted time includes 'AM' or 'PM', it's a 12-hour clock
  return formattedTime.includes('AM') || formattedTime.includes('PM');
}

function getColorFromPercentage(numerator, denominator, lowerBound, upperBound, lowerBetter = true, colors = ["danger", "purple", "primary"]) {
  if (!denominator || colors.length < 3) {
    throw new Error("Invalid input: Denominator must not be zero and three colors must be provided.");
  }
  // Calculate the percentage
  const percentage = (numerator / denominator) * 100;
  // Determine and return color based on the percentage range
  if (percentage < lowerBound) {
    if (lowerBetter) {
      return colors[2];
    }
    return colors[0]; // Color for below lowerBound
  } else if (percentage >= lowerBound && percentage <= upperBound) {
    return colors[1]; // Color for between lower and upper bounds
  } else {
    if (lowerBetter) {
      return colors[0];
    }
    return colors[2]; // Color for above upperBound
  }
}

function partialObject(func, partialArgs) {
  return function (newArgs) {
    return func({ ...partialArgs, ...newArgs });
  };
}

/**
 * Simplifies data for gauge charts by ensuring each array property of the provided object
 * is replaced by its first element.
 * 
 * @param {Object} data - The data object to be simplified. It modifies the original object.
 */
function simplifyDataForNotGauge(data) {
  for (const key in data) {
    if (Array.isArray(data[key])) {
      data[key] = data[key][0];
    }
  }
}

/**
 * Prepares chart data by adding a `displayValue` to each item in the array.
 * The `displayValue` is either the count or the percentage of the total.
 * 
 * @param {Array} data - The array of data items from chartData.value.
 * @param {string} dropdownSelection - The current dropdown selection ("percentage" or other).
 * @returns {Array} - The transformed array with updated `displayValue` for each item.
 */
function prepareCategoricalData(data, dropdownSelection) {
  let totalDcount = data.reduce((total, item) => total + item.dcount, 0);

  const dataPreparation = item => {
    if (dropdownSelection === "percentage") {
      let percent = (item.dcount / totalDcount) * 100;
      return {
        ...item,
        displayValue: percent.toFixed(2) // Optional: Format to 2 decimal places
      };
    } else {
      return {
        ...item,
        displayValue: item.dcount
      };
    }
  };

  return data.map(dataPreparation);
}


function prefersCelsius() {
  const locale = navigator.language || navigator.userLanguage;
  // Countries that primarily use Fahrenheit
  const fahrenheitCountries = ['US', 'BS', 'BZ', 'KY', 'PW'];
  // Extract the country code from the locale
  const country = locale.split('-')[1] || locale;
  return !fahrenheitCountries.includes(country.toUpperCase());
}

function celsiusToFahrenheit(celsius, decimals = 1) {
  return ((celsius * 9 / 5) + 32).toFixed(decimals);
}

function temperatureUnit(celsius) {
  if (isNaN(celsius)) return celsius;
  if (prefersCelsius()) {
    return celsius;
  }
  return celsiusToFahrenheit(celsius);
}

function applyTargetAnnotations(options, target, chartData) {
  // Check if the target is defined
  if (typeof target !== 'undefined' && target !== null) {
    options.plugins = {
      ...options.plugins,
      annotation: {
        annotations: {
          // Line annotation for the target
          line1: {
            type: 'line',
            yMin: target,  // Start the line at the target value
            yMax: target,  // End the line at the target value (horizontal line)
            borderColor: graphColorsJSON["danger"],  // Line color
            borderWidth: 2,  // Line thickness
          },
          // Label annotation for the target line
          label1: {
            type: 'label',
            xValue: chartData.name,  // Align the label with the bar
            yValue: target,  // Set the y position to the target value
            backgroundColor: graphColorsJSON["danger"],  // Label background
            color: blackColor,  // Text color
            content: `Target: ${target}`,  // The label content
            borderRadius: 4,  // Rounded corners for the label background
            position: 'center',  // Place the label at the center of the line
          }
        }
      }
    };
  }
  return options;
}

function applyTargetAnnotationsToGrouped(options, targets, cleanData) {
  let isNotNull = value => value != null;
  if (targets.filter(isNotNull).length === 0) return options;

  // Ensure annotations object exists
  options.plugins = {
    ...options.plugins,
    annotation: {
      annotations: {}
    }
  };

  // Get the labels (e.g., "Tuesday", "Friday") from cleanData
  const labels = Object.keys(cleanData.value);
  const topThreshold = 0.95 * Math.max(...Object.values(cleanData.value));

  // Loop through the targets array and add a line and label annotation for each target
  targets.forEach((target, index) => {
    const label = labels[index];  // The corresponding label (day)

    // Add a line annotation for each target, limited to the corresponding bar
    options.plugins.annotation.annotations[`line${index + 1}`] = {
      type: 'line',
      yMin: target,  // The y value for the target line
      yMax: target,  // This creates a horizontal line
      xMin: index - 0.5,  // Start the line just before the bar
      xMax: index + 0.5,  // End the line just after the bar
      borderColor: graphColorsJSON["danger"],  // Color for the target line
      borderWidth: 2,  // Thickness of the target line
    };

    const isNearTop = target >= topThreshold;

    // Add a separate label annotation for each target
    options.plugins.annotation.annotations[`label${index + 1}`] = {
      type: 'label',
      xValue: label,  // The label (e.g., "Tuesday", "Friday")
      yValue: isNearTop? target * 0.95 : target,  // Y position for the label
      backgroundColor: graphColorsJSON["danger"],  // Background for the label
      color: blackColor,  // Text color for the label
      content: targets.length > 7 ? target : `Target: ${target}`,  // Label content showing the target value
      borderRadius: 4,  // Rounded corners for the label
      rotation: targets.length > 7 ? -90 : 0,  // Rotate by -90 degrees if more than 7 targets
    };
  });

  return options;
}

export {
  isObjectNotEmpty,
  sortArrayByDcountAndValue,
  firstLetterCaps,
  countDigits,
  findMaxDigits,
  arraysToObject,
  concatenateArrays,
  extractValuesFromJSON,
  dbToLocale,
  formatTimestamp,
  formatStdDateLocaleTime,
  formatTimeToLocale,
  getColorFromPercentage,
  partialObject,
  simplifyDataForNotGauge,
  prepareCategoricalData,
  temperatureUnit,
  prefersCelsius,
  applyTargetAnnotations,
  applyTargetAnnotationsToGrouped,
}
