import { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/Card";
import useFetch from "use-http";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { emailRegex } from "../Utils/Regex";

export default function ForgotPasswordPage() {
  const navigate = useNavigate();
  const { post, response } = useFetch(process.env.REACT_APP_API_URL, {
    cachePolicy: "no-cache",
  });
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [returnMessage, setReturnMessage] = useState("");
  const { t } = useTranslation();

  const _handleSubmit = async () => {
    if (!email) {
      setError("Email is required.");
    } else if (!emailRegex.test(email)) {
      setError("Email is invalid.");
    } else {
      setError(null);
      setReturnMessage("Sending. You should receive an email shortly.");
      let data = await post("/api/user/reset-password-email", { email });
      if (!response.ok) {
        setError("Something went wrong; please contact admin.");
        setReturnMessage("");
      }
    }
  };

  return (
    <Col xs={6} md={4} lg={3} xl={2} className="mx-auto">
      <br />
      <Image src="/static/logo-white.png" fluid />
      <br />
      <br />
      <Card>
        <Card.Body>
          <Form>
            {error && <Alert variant="danger">{error}</Alert>}
            {!returnMessage &&
              <>
                <Form.Group className="mb-3" controlId="resetPasswordForm.email">
                  <Form.Label>{t("Email")}</Form.Label>
                  <Form.Control
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <div className="d-grid gap-2">
                  <Button
                    className="rounded-pill"
                    variant="primary"
                    onClick={_handleSubmit}
                  >
                    {t("Reset password")}
                  </Button>
                </div>
              </>}
            {returnMessage &&
              <>
                <Alert variant="info">{returnMessage}</Alert>
                <div className="d-grid gap-2">
                  <Button
                    className="rounded-pill"
                    variant="primary"
                    onClick={() => navigate("/login")}
                  >
                    {t("Login page")}
                  </Button>
                </div>
              </>}
          </Form>
        </Card.Body>
      </Card>
    </Col>
  );
}
