import React from "react";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';

export const VideoGallery = (props) => {
  const { urls, videoLimit } = props;
  
  function isVideoUrl(url) {
    try {
      const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'wmv', 'flv', 'mkv'];
      const ext = new URL(url).pathname.split('.').pop();
      return videoExtensions.includes(ext);
    } catch(e) {
      console.log(e);
      return false;
    }
  }
  
  return (
    <>
      <Row>
        {urls.length > 0 && urls.filter((url)=> isVideoUrl(url.url)).slice(0, videoLimit).map((url, idx) => {
          return (
            <Col xs={6} md={4} lg={4} xl={4}>
              <div key={idx} style={{ borderRadius: '15px' }} >
                <video style={{
                  width: '95%',
                  height: 'auto',
                  borderRadius: 8,
                }} controls src={url.url} />
              </div>
            </Col>
          )
        })}
      </Row>
    </>
  );
};
