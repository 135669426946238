import React from "react";
import { Chart } from "react-chartjs-2";
import { graphColors } from "../../../Utils/GraphColors";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { generateChartOptions, sortObjectByKeys, fillMissingKeys, twoLinearYScales } from "../../../Utils/chartJsOptions";
import { simplifyDataForNotGauge } from "../../../Utils/utilFunctions";
import zoomPlugin from "chartjs-plugin-zoom";
import GroupBarChart from "../Simple/GroupBarChart";
import OverlayNumericCategoricalGroupBarChart from "./OverlayNumericCategoricalGroupBarChart";
import OverlayNumericWeatherGroupBarChart from "./OverlayNumericWeatherGroupBarChart";
import CategoricalGroupBarChart from "../Categorical/CategoricalGroupBarChart";
import { applyTargetAnnotationsToGrouped } from "../../../Utils/utilFunctions";

// Accepts Chart Data as Returned by the API and renders a chart
// This class should be the only thing that changes if the api output does
export default function OverlayGroupBarChart(props) {
  const { chartData, overlay, fraction } = props;

  let cleanData = JSON.parse(JSON.stringify(chartData)); // Create a deep copy
  let cleanOverlay = JSON.parse(JSON.stringify(overlay)); // Create a deep copy
  let targets = cleanData.target ? cleanData.target : [];

  if (cleanOverlay && typeof cleanOverlay.value !== "object") return; 
  if (typeof (cleanOverlay) == 'undefined' || cleanOverlay == null || !cleanOverlay.value) {
    return <GroupBarChart chartData={cleanData} targets={targets} fraction={fraction} />;
  }

  if (cleanOverlay && cleanOverlay.type === "categorical") {
    if (cleanData.type === "sum") {
      return <CategoricalGroupBarChart chartData={cleanOverlay} dropdownSelection={"count"} />
    }
    return <OverlayNumericCategoricalGroupBarChart chartData={cleanData} targets={targets} overlay={cleanOverlay} fraction={fraction} />
  }
  if (cleanOverlay && cleanOverlay.type === "weather") {
    return <OverlayNumericWeatherGroupBarChart chartData={cleanData} targets={targets} overlay={cleanOverlay} fraction={fraction} />
  }

  // // Handle Gauge type adjustment if necessary
  simplifyDataForNotGauge(cleanData.value);
  simplifyDataForNotGauge(cleanOverlay.value);

  let filledChartData = JSON.parse(JSON.stringify(cleanData.value));
  let filledOverlayData = JSON.parse(JSON.stringify(cleanOverlay.value));

  // Fill missing keys in both directions
  fillMissingKeys(filledOverlayData, cleanData.value);
  fillMissingKeys(filledChartData, cleanOverlay.value);

  filledChartData = sortObjectByKeys(filledChartData);
  filledOverlayData = sortObjectByKeys(filledOverlayData);

  const chartJsSetup = {
    labels: Object.keys(filledChartData),
    datasets: [
      {
        plotType: cleanData.type,
        label: cleanData.name,
        data: Object.values(filledChartData),
        backgroundColor: graphColors[0], // Single color for all chartData bars
        borderRadius: 8,
        yAxisID: "y",
      },
      {
        plotType: cleanOverlay.type,
        label: cleanOverlay.name,
        data: Object.values(filledOverlayData),
        backgroundColor: graphColors[1], // Different color for all overlay bars
        borderRadius: 8,
        yAxisID: "yR",
      },
    ],
  };

  let options = generateChartOptions({})
  options.scales = twoLinearYScales(options, cleanData, cleanOverlay);
  options = applyTargetAnnotationsToGrouped(options, targets, cleanData);

  return (
    <Chart
      type="bar"
      data={chartJsSetup}
      options={options}
      plugins={[ChartDataLabels, zoomPlugin]}
    />
  );
}
