import { useState, useEffect } from "react";
import useFilterStore from "../Hooks/useFilterStore";

export const handleFilterGroupSwitchChange = (
  value,
  isChecked,
  label,
) => {
  // Update selectedFilterGroups based on isChecked
  const { setSelectedFilterGroups, setFilterGroupSwitchStates, selectedFilterGroups, filterGroupSwitchStates } = useFilterStore.getState();

  if (isChecked) {
    const existingValues = selectedFilterGroups[label];
    if (!existingValues) {
      // If the label doesn't exist, create a new entry with the value in an array.
      setSelectedFilterGroups({ ...selectedFilterGroups, [label]: [value] });
    } else {
      // If the label exists, check if the value is already included.
      if (!existingValues.includes(value)) {
        // If the value is not included, add it to the list for the label.
        setSelectedFilterGroups({ ...selectedFilterGroups, [label]: [...existingValues, value] });
      }
    }
    const groupSwitchState = {
      ...filterGroupSwitchStates,
      [label]: {
        ...filterGroupSwitchStates[label],
        [value]: true,
      },
    }
    setFilterGroupSwitchStates(groupSwitchState);
  } else {
    handleRemoveFilterGroup(value, label, setSelectedFilterGroups, setFilterGroupSwitchStates);
  }
};

export const handleRemoveFilterGroup = (
  value,
  label,
) => {
  const { setSelectedFilterGroups, setFilterGroupSwitchStates, selectedFilterGroups, filterGroupSwitchStates } = useFilterStore.getState();
  const updatedGroups = { ...selectedFilterGroups };
  if (updatedGroups[label]) {
    updatedGroups[label] = updatedGroups[label].filter((item) => item !== value);
    if (updatedGroups[label].length === 0) {
      delete updatedGroups[label];
    }
  }
  setSelectedFilterGroups(updatedGroups);
  const groupSwitchState = {
    ...filterGroupSwitchStates,
    [label]: {
      ...filterGroupSwitchStates[label],
      [value]: false,
    },
  }
  setFilterGroupSwitchStates(groupSwitchState);
};

function filterTrueValues(obj) {
  return Object.entries(obj)
    .filter(([_, value]) => value === true)
    .map(([key, _]) => key);
}
export const useSwitchableSelectionState = (selected, setSelected) => {

  useEffect(() => {
    const selectedCopy = [...selected];
    const selectedObject = {};
    for (const item of selectedCopy) {
      selectedObject[item] = true;
    }
    setSwitchState(selectedObject);
  }, [selected]);
  const [switchState, setSwitchState] = useState([]);

  const handleAddItem = (value, isChecked) => {
    if (isChecked) {
      const addState = { ...switchState, [value]: true };
      const filteredSwitchStateArray = filterTrueValues({ ...addState });
      setSelected(filteredSwitchStateArray);
    } else {
      handleRemoveItem(value);
    }
  };

  const handleRemoveItem = (value) => {
    const removeState = { ...switchState, [value]: false };
    const filteredSwitchStateArray = filterTrueValues({ ...removeState });
    setSelected(filteredSwitchStateArray);
  }
  return {
    switchState,
    setSwitchState,
    handleAddItem,
    handleRemoveItem,
  };
};

export function processFilterGroups(data, setFilterGroups, setFilterGroupSwitchStates) {
  const filterGroupJSON = {};

  // Populate filterGroupJSON as an object with labels as keys and Sets as values
  data.forEach((group) => {
    if (!filterGroupJSON[group.label]) {
      filterGroupJSON[group.label] = new Set();
    }
    filterGroupJSON[group.label].add(group.value);
  });

  // Convert Sets to Arrays for each label in filterGroupJSON
  Object.keys(filterGroupJSON).forEach((label) => {
    filterGroupJSON[label] = [...filterGroupJSON[label]];
  });

  setFilterGroups(filterGroupJSON);
  // Create filterGroupSwitchStates with false as default state
  setFilterGroupSwitchStates(
    Object.entries(filterGroupJSON).reduce(
      (acc, [key, options]) => {
        acc[key] = options.reduce((optionAcc, option) => {
          optionAcc[option] = false; // false; nothing selected initially
          return optionAcc;
        }, {});
        return acc;
      },
      {}
    )
  )
}