import React, { useRef, useEffect, useState } from "react";
import { WordCloudChart } from 'chartjs-chart-wordcloud';
import { getColorSingleWordcloud } from "../../../Utils/GraphColorPicker";
import { sortArrayByDcountAndValue } from "../../../Utils/utilFunctions";

export default function WordcloudSingleChart(props) {
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const containerRef = useRef(null);
  const { chartData } = props;
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  const scaleDcount = (dcount, minSize, maxSize, minDcount, maxDcount) => {
    if (minDcount === maxDcount) {
      return Math.floor((minSize + maxSize) / 2);
    }
    const sizeRange = maxSize - minSize;
    const scaledSize = ((dcount - minDcount) / (maxDcount - minDcount)) * sizeRange + minSize;
    return Math.floor(scaledSize);
  };

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        setContainerSize({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight
        });
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    try {
      if (canvasRef.current && !chartRef.current && chartData && chartData.value && containerSize.width > 0 && containerSize.height > 0) {
        const ctx = canvasRef.current.getContext('2d');
        const filteredChartData = sortArrayByDcountAndValue(chartData.value).slice(0, 100);
        const maxDcount = Math.max(...filteredChartData.map(d => d.dcount));
        const minDcount = Math.min(...filteredChartData.map(d => d.dcount));
        // Scale dcount to a range of 15-90; play around 15 - 100 to get good results
        const dataWithFontSize = filteredChartData.map((d, index) => ({
          ...d,
          size: scaleDcount(d.dcount, 15, 90, minDcount, maxDcount),
          color: getColorSingleWordcloud(index)
        }));

        chartRef.current = new WordCloudChart(ctx, {
          type: 'wordCloud',
          data: {
            labels: dataWithFontSize.map(d => d.value),
            datasets: [{
              data: dataWithFontSize.map(d => ""),
              color: dataWithFontSize.map(d => d.color),
              size: dataWithFontSize.map(d => d.size),
              dcount: dataWithFontSize.map(d => d.dcount),
            }],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return `${context.label}: ${context.dataset.dcount[context.dataIndex]} (Sample Size: ${chartData.value.length})`;
                  },
                  // rectangle color when hovered
                  labelColor: function (context) {
                    return {
                      backgroundColor: context.dataset.color[context.dataIndex],
                    };
                  },
                }
              },
              legend: {
                display: false
              },
            },
            layout: {
              padding: {
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
              }
            },
            elements: {
              word: {
                minRotation: 0,
                maxRotation: 0,
              }
            }
          }
        });
      }
    } catch (error) {
      console.error("Error occurred while setting up the word cloud chart:", error);
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [chartData, containerSize]);

  return (
    <div ref={containerRef} style={{ width: "100%", height: "440px", position: "relative" }}>
      <canvas ref={canvasRef} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} />
    </div>
  );
}