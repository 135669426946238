import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useFetchConfig from "../../Hooks/useFetchConfig";
import useFetch from "use-http";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

import { MainContext } from "../../Providers/MainContext";

export default function CustomerEditPage() {
  const { customer } = useContext(MainContext); // checks if user has a admin role
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const fetchConfig = useFetchConfig();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const { patch, get, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );

  useEffect(() => {
    getCustomer();
  }, [id]);

  const getCustomer = async () => {
    let data = await get(`/api/customer/${id}`);
    if (response.ok) {
      setTitle(data.title);
      setDescription(data.description);
      setImageURL(data.image);
      setError(null);
      return;
    } else {
      // Handle errors, e.g., set error message in state
      setError("An error occurred while fetching the customer.");
      // navigate('/customers');
      console.error("Error fetching customer: ");
    }
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (imageFile) {
      formData.append("image", imageFile); // 'image' is the field name for the image file
    }

    await patch(`/api/customer/${id}`, formData, {
      // Important: Set the content type to multipart/form-data
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (response.ok) {
      setError(null);
      alert("Customer updated successfully!");
      navigate(`/customers`);
    } else {
      // Handle errors, e.g., set error message in state
      setError("An error occurred while saving the customer.");
    }
  };

  return (
    <Col xs={8} md={6} lg={6} xl={6} className="mx-auto">
      <br />
      <h1 className="text-light">{"Edit Customer"}</h1>
      <br />
      <Row>{error && <p className="text-danger">{error}</p>}</Row>
      {customer && customer.admins && (
        <Row>
          <Col xs={8} md={8} lg={8} xl={8} className="mx-auto">
            <Form>
              <Form.Group className="mb-3" controlId="dashboardEdit.title">
                <Form.Label className="text-light">{t("Title")}</Form.Label>
                <Form.Control
                  className="text-light"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="dashboardEdit.description"
              >
                <Form.Label className="text-light">
                  {t("Description")}
                </Form.Label>
                <Form.Control
                  className="text-light"
                  as="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col xs={6} className="d-flex align-items-center">
                    <Form.Label className="text-light mb-0">
                      {t("Change Image")}
                    </Form.Label>
                  </Col>
                  <Col xs={6} className="d-flex justify-content-end mb-3">
                    {imageURL && (
                      <img
                        src={imageURL}
                        alt="Current"
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control
                      type="file"
                      onChange={(e) => setImageFile(e.target.files[0])}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Button onClick={handleSave}>{t("Save")}</Button>
            </Form>
          </Col>
        </Row>
      )}
    </Col>
  );
}
