import React from "react";
import { Chart } from "react-chartjs-2";
import { getColor } from "../../../Utils/GraphColorPicker";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { modalCategoryOverlayOptions, imagePlugin, twoLinearYScales } from "../../../Utils/chartJsOptions";
import { applyTargetAnnotations } from "../../../Utils/utilFunctions";

export default function OverlayNumericWeatherBarChart(props) {
    const { chartData, target, overlay } = props;

    let options = modalCategoryOverlayOptions(overlay, chartData, chartData.y_max, true);
    options.scales = twoLinearYScales(options, chartData, overlay);
    options = applyTargetAnnotations(options, target, chartData);

    const chartJsSetup = {
        labels: [chartData.name],
        datasets: [
            {
                label: chartData.name,
                data: [chartData.value],
                backgroundColor: getColor(0),
                borderRadius: 8,
                yAxisID: 'y'
            },
            {
                label: overlay.name,
                data: [overlay.value['temp_c']],
                backgroundColor: getColor(1), // Ensure this picks a different color
                borderRadius: 8,
                yAxisID: 'yR',
                imageUrl: overlay.value['url']
            }
        ]
    };

    return (
        <Chart
            type="bar"
            data={chartJsSetup}
            options={options}
            plugins={[ChartDataLabels, imagePlugin]} // Add imagePlugin here
        />
    );
}