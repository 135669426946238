import OverlayCategoricalOnCategoricalHeatMap from './OverlayCategoricalOnCategoricalHeatMap';
import { Container, Row, Col } from 'react-bootstrap';

const OverlayCategoricalOnCategoricalGroupHeatMap = (props) => {
  const { overlay } = props;
  let size = 6;
  // if the objects have one, make the size bigger
  // otherwise, show two in a row
  if (Object.keys(overlay['value']).length === 1) {
      size = 12
  }
  return <Container>
    <Row>
      {Object.entries(overlay['value']).map(([key, value]) => {
          const processedData = {};
          processedData['categorical'] = value;
          return <Col className="p-0" md={size}>
            {/* grouped labels */}
            <div style={{ textAlign: 'center' }}>{key}</div>
            <OverlayCategoricalOnCategoricalHeatMap overlay={processedData} />;
          </Col>
      })}
  </Row>
  </Container>
};

export default OverlayCategoricalOnCategoricalGroupHeatMap;