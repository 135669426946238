import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import useFetchConfig from "../../Hooks/useFetchConfig";
import useFetch from "use-http";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";

export default function CustomerUserListTable(props) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const fetchConfig = useFetchConfig();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [users, setUsers] = useState([]);
  const { get, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );

  useEffect(() => {
    setSelectedUserIds(props.selectedUserIds);
  }, [props.selectedUserIds]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    let data = await get(`/api/customer/users`);
    if (response.ok) {
      setError(null);
      setUsers(data);
    } else {
      setError("Users not found");
    }
  };

  return (
    <Col className="mx-auto">
      <br />
      <h1 className="text-light">{props.title}</h1>
      <br />
      <Row>{error && <p className="text-danger">{error}</p>}</Row>
      {users && (
        <Row>
          <Table hover className="rounded-3 overflow-hidden">
            <thead>
              <tr className="border-0">
                <th scope="col">{t("ID")}</th>
                <th scope="col">{t("Username")}</th>
                <th scope="col">{t("Email")}</th>
                <th scope="col">{t("Action")}</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id} className="border-0">
                  <td>{user.id}</td>
                  <td>{user.username}</td>
                  <td>{user.email}</td>
                  <td>
                    <Button
                      onClick={() => {
                        if (selectedUserIds.includes(user.id)) {
                          props.handleRemoveUser(user.id);
                        } else {
                          props.handleAddUser(user.id);
                        }
                      }}
                    >
                      {selectedUserIds.includes(user.id)
                        ? t("Remove")
                        : t("Add")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      )}
    </Col>
  );
}
