import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useFetchConfig from "../../Hooks/useFetchConfig";
import useFetch from "use-http";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";

import { MainContext } from "../../Providers/MainContext";

export default function CustomerCreatePage() {
  const { customer } = useContext(MainContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const fetchConfig = useFetchConfig();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const { post, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    if (imageFile) {
      formData.append("image", imageFile); // 'image' is the field name for the image file
    }

    await post(`/api/customer/new`, formData, {
      // Important: Set the content type to multipart/form-data
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (response.ok) {
      setError(null);
      navigate(`/customers`);
    } else {
      // Handle errors, e.g., set error message in state
      setError("An error occurred while saving the customer.");
    }
  };

  return (
    <Col xs={8} md={6} lg={6} xl={6} className="mx-auto">
      <br />
      <h1 className="text-light">{"Create Customer"}</h1>
      <br />
      <Row>{error && <p className="text-danger">{error}</p>}</Row>
      {customer && customer.admins && (
        <Row>
          <Col xs={8} md={8} lg={8} xl={8} className="mx-auto">
            <Form>
              <Form.Group className="mb-3" controlId="dashboardEdit.title">
                <Form.Label className="text-light">{t("Title")}</Form.Label>
                <Form.Control
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="dashboardEdit.description"
              >
                <Form.Label className="text-light">
                  {t("Description")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-light">{t("Image")}</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setImageFile(e.target.files[0])}
                />
              </Form.Group>
              <Button onClick={handleSave}>{t("Save")}</Button>
            </Form>
          </Col>
        </Row>
      )}
    </Col>
  );
}
