export const graphColorsJSON = {
  "primary-teal": "rgba(8,190,178,0.8)",
  "purple": "rgba(134,126,200,0.8)",
  "blue": "rgba(44,86,151,0.8)",
  "danger": "rgba(222,110,134,0.8)",
  "cyan": "rgba(65,191,241,0.8)"
}

export const graphColors = Object.values(graphColorsJSON);
export const blackColor = 'rgba(0, 0, 0, 1)';
export const whiteColor = 'rgba(255, 255, 255, 1)';
export const primaryNavy = '#1c253a'
