import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Table } from "react-bootstrap";

import { MainContext } from "../../Providers/MainContext";
import { SetEOSRules } from "./SetEOSRules";
import { AddSurvey } from "./AddSurvey";

// Helper function to get a link for survey sources
const getLink = (survey, label, mapping) => {
  if (survey.source && mapping[survey.source]) {
    return <a href={mapping[survey.source]}>{label}</a>;
  } else {
    return null;
  }
};

// Admin link for survey sources
const sourceLink = (survey) => {
  const sourceMapping = {
    voiceform: `https://app.voiceform.com/dashboard/voiceform/${survey.form_id}/question/welcome_screen`,
    typeform: `https://admin.typeform.com/form/${survey.form_id}/create`,
  };
  return getLink(survey, survey.form_id, sourceMapping);
};

// Share link for survey sources
const shareLink = (survey) => {
  const shareMapping = {
    voiceform: `https://app.voiceform.com/dashboard/voiceform/${survey.form_id}/share`,
    typeform: `https://admin.typeform.com/form/${survey.form_id}/share`,
  };
  return getLink(survey, "Share", shareMapping);
};

// Main SurveysTable component
export default function SurveysTable(props) {
  const { t } = useTranslation();
  const { canImpersonate } = useContext(MainContext);
  const { surveys: initialSurveys } = props;
  // State to manage surveys
  const [surveys, setSurveys] = useState(initialSurveys);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [eosModalOpen, setEosModalOpen] = useState(false);
  const [eosSurvey, setEosSurvey] = useState(null);
  const [updatedSurveys, setUpdatedSurveys] = useState(null);
  const [maxPriority, setMaxPriority] = useState(null);

  // Update sortConfig to hold multiple keys
  const [sortConfig, setSortConfig] = useState([
    { key: "source", direction: "asc" },
    { key: "priority", direction: "desc" },
    { key: "title", direction: "asc" },
  ]);

  useEffect(() => {
    setSurveys(initialSurveys);
  }, [initialSurveys]);

  // UseEffect to update the surveys once updatedSurveys changes
  useEffect(() => {
    if (updatedSurveys) {
      setSurveys(updatedSurveys);
    }
  }, [updatedSurveys]);

  useEffect(() => {
    // Extract priorities and find the maximum value
    const maxPriority = Math.max(
      ...surveys.map((survey) => survey.priority || 0),
    );
    setMaxPriority(maxPriority);
  }, [surveys]);

  const primaryRangeModal = (dSurvey) => {
    setEosSurvey(dSurvey);
    setEosModalOpen(true);
  };

  // Modify the sortedSurveys function to handle multiple sorting keys
  const sortedSurveys = (surveys) => {
    return [...surveys].sort((a, b) => {
      for (let { key, direction } of sortConfig) {
        const aValue = key === "priority" ? a[key] : a.survey[key];
        const bValue = key === "priority" ? b[key] : b.survey[key];

        if (aValue < bValue) {
          return direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === "asc" ? 1 : -1;
        }
        // If values are equal, move on to the next key in sortConfig
      }
      return 0; // All keys have been checked and are equal
    });
  };

  // Update requestSort to handle multiple keys
  const requestSort = (key) => {
    setSortConfig((currentConfig) => {
      const existingIndex = currentConfig.findIndex(
        (config) => config.key === key,
      );
      let newConfig;

      if (existingIndex >= 0) {
        // If the key already exists, toggle its direction
        const direction =
          currentConfig[existingIndex].direction === "asc" ? "desc" : "asc";
        newConfig = [
          ...currentConfig.slice(0, existingIndex),
          { key, direction },
          ...currentConfig.slice(existingIndex + 1),
        ];
      } else {
        // If the key doesn't exist, add it to the sortConfig array
        newConfig = [...currentConfig, { key, direction: "asc" }];
      }
      return newConfig;
    });
  };

  // Render the sorted surveys by calling sortedSurveys(surveys)
  const renderedSurveys = sortedSurveys(surveys);

  const renderSortableHeader = (label, columnKey) => {
    // Find the sort configuration for this column, if it exists
    const sortColumn = sortConfig.find((config) => config.key === columnKey);
    const sortIndicator = sortColumn
      ? sortColumn.direction === "asc"
        ? "▲"
        : "▼"
      : "";

    return (
      <th scope="col" onClick={() => requestSort(columnKey)}>
        {t(label)} {sortIndicator}
      </th>
    );
  };

  return (
    <Col className="mx-auto">
      <br />
      <Row>
        <Col className="mx-auto">
          <h1 className="text-light">{t("Surveys")}</h1>
        </Col>
        <Col className="mx-auto">
          <Button
            onClick={(() => setAddModalOpen(true))}
            className="w-100"
          >
            {t("Add Survey")}
          </Button>
        </Col>
      </Row>
      <br />
      <SetEOSRules
        dSurvey={eosSurvey}
        eosModalOpen={eosModalOpen}
        setEosModalOpen={setEosModalOpen}
        onUpdatePrimary={setUpdatedSurveys}
        maxPriority={maxPriority}
      />
      <AddSurvey
        dashboardId={surveys?.[0]?.dashboard?.id}
        modalOpen={addModalOpen}
        setModalOpen={setAddModalOpen}
      />
      <Row>
        <Table hover className="rounded-3 overflow-hidden">
          <thead>
            <tr className="border-0">
              {renderSortableHeader("Title", "title")}
              {renderSortableHeader("Source", "source")}
              {renderSortableHeader("Admin", "form_id")}
              <th scope="col">{t("Survey")}</th>
              {canImpersonate &&
                renderSortableHeader("Rule Priority", "priority")}
            </tr>
          </thead>
          <tbody>
            {renderedSurveys.map((dSurvey) => (
              <tr key={dSurvey.survey.id} className="border-0">
                <td>{dSurvey.survey.title}</td>
                <td>{dSurvey.survey.source}</td>
                <td>{sourceLink(dSurvey.survey)}</td>
                <td>{shareLink(dSurvey.survey)}</td>
                {canImpersonate && (
                  <>
                    <td>
                      {dSurvey.survey.source === "typeform" &&
                        dSurvey.survey.title.startsWith("EOS") && (
                          <Button
                            variant="primary text-white"
                            className="w-100"
                            onClick={() => primaryRangeModal(dSurvey)}
                          >
                            {dSurvey.priority ?? "-"}
                          </Button>
                        )}
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Col>
  );
}
