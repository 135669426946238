import { useContext } from "react";
import { MainContext } from "../Providers/MainContext";

export default function useFetchConfig() {
  const { jwt, impersonatedCustomerId } = useContext(MainContext);
  let headers = {};
  if (jwt) {
    headers["Authorization"] = "Bearer " + jwt;
  }
  if (impersonatedCustomerId) {
    headers["customer"] = impersonatedCustomerId.toString();
  }
  return {
    headers: headers,
    cachePolicy: "no-cache",
  };
}
