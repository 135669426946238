import React, { useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Form, Button, Container, Alert, Col, Row } from "react-bootstrap";
import { MainContext } from "../Providers/MainContext";
import useFetch from "use-http";
import { useTranslation } from "react-i18next";
import { passwordRegex } from "../Utils/Regex";

const ResetPasswordPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [resetData, setResetData] = useState({
    password: "",
    repeatPassword: "",
    token: token,
  });
  const [error, setError] = useState(null);

  const [formErrors, setFormErrors] = useState({});
  const [validated, setValidated] = useState(false);
  const { setJwt, setCustomer, setUser } = useContext(MainContext);

  const { post, response } = useFetch(process.env.REACT_APP_API_URL, {
    cachePolicy: "no-cache",
  });
  const { t } = useTranslation();

  const handleChange = (e) => {
    setResetData({
      ...resetData,
      [e.target.name]: e.target.value,
    });
    // Optionally reset errors
    setFormErrors({ ...formErrors, [e.target.name]: "" });
  };

  const validate = () => {
    const newErrors = {};

    // Password validation
    if (!resetData.password) {
      newErrors.password = "Password is required.";
    } else if (!passwordRegex.test(resetData.password)) {
      newErrors.password =
        "Password must include at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long.";
    }

    // Password repeated
    if (!resetData.repeatPassword) {
      newErrors.repeatPassword = "Password must be repeated."
    } else if (resetData.password !== resetData.repeatPassword) {
      newErrors.repeatPassword = "Password repetition must be identical."
    }

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      e.stopPropagation();
      setValidated(false); // Set validated to False to trigger Bootstrap's validation styles
      return; // Stop submission since there are errors
    }

    let data = await post("/api/reset-password", resetData);

    if (response.ok) {
      setError(null);
      setJwt(data.access, {
        days: 1,
        SameSite: "Strict",
        Secure: true,
      });
      setUser(data.user)
      setCustomer(data.customer)
      navigate("/home");
    } else {
      setError(data.message);
    }
  };

  return (
    <Container className="mt-5">
      {/* make the h2 middle */}

      <Row>
        <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
          <h2 className="text-white">{t("Reset password")}</h2>
        </Col>
      </Row>
      <Form noValidate onSubmit={handleSubmit}>
        {/* Password */}
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Form.Group className="mb-3">
              <Form.Label className="text-white">{t("Password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("Password")}
                name="password"
                value={resetData.password}
                onChange={handleChange}
                isInvalid={!!formErrors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.password}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {/* Repeat Password */}
        <Row>
          <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
            <Form.Group className="mb-3">
              <Form.Label className="text-white">{t("Repeat password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("Password")}
                name="repeatPassword"
                value={resetData.repeatPassword}
                onChange={handleChange}
                isInvalid={!!formErrors.repeatPassword}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.repeatPassword}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {/* Error */}
        {error &&
          <>
            <Row>
              <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
                <Alert variant="danger">{error}</Alert>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
                <Button
                  variant="primary"
                  onClick={() => { navigate(`/forgot-password`) }}>
                  {t("Restart")}
                </Button>
              </Col>
            </Row>
          </>}
        {/* Submit Button */}
        {!error &&
          <Row>
            <Col xs={6} md={4} lg={3} xl={3} className="mx-auto">
              <Button variant="primary" type="submit">
                {t("Reset")}
              </Button>
            </Col>
          </Row>}
      </Form>
    </Container>
  );
};

export default ResetPasswordPage;
