import React from "react";
import CategoricalGroupBarChart from "../Categorical/CategoricalGroupBarChart";
import CategoricalGroupPieChart from "../Categorical/CategoricalGroupPieChart";
import OverlayCategoricalOnCategoricalGroupHeatMap from "./OverlayCategoricalOnCategoricalGroupHeatMap";
// Accepts chartData and overlay (same format as chartData) as returned by the API and renders a chart
export default function OverlayCategoricalGroupChart(props) {
    const { chartData, overlay, dropdownSelection } = props;
    if (!overlay || overlay.value === undefined || overlay.value === "No data.") {
        if (dropdownSelection === "pie") {
            return (
                <CategoricalGroupPieChart
                    chartData={chartData}
                    dropdownSelection={dropdownSelection}
                />
            );
        }
        return (
            <CategoricalGroupBarChart
                chartData={chartData}
                dropdownSelection={dropdownSelection}
            />
        );
    }
    if (overlay && overlay.type === "categorical") {
        return <OverlayCategoricalOnCategoricalGroupHeatMap overlay={overlay} />
    }
    return (
        <CategoricalGroupBarChart
            chartData={chartData}
            dropdownSelection={dropdownSelection}
        />
    );
}
