import { useState, useContext, useEffect } from "react";

import useFetch from "use-http";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import useFetchConfig from "../../Hooks/useFetchConfig";

import { MainContext } from "../../Providers/MainContext";

export default function MerchEditModal(props) {
  const fetchConfig = useFetchConfig();
  const { patch, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const { customer } = useContext(MainContext);
  const [brand, setBrand] = useState(null);
  const [category, setCategory] = useState(null);
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setBrand(props.merch.brand);
    setCategory(props.merch.category);
    setProduct(props.merch.product);
  }, [props.merch]);

  const handleClose = () => props.handleClose();

  const handleSubmit = async () => {
    await patch(`/api/customer/${customer.id}/merch/${props.merch.id}`, {
      brand,
      category,
      product,
    });
    if (response.ok) {
      setError(null);
      handleClose();
    } else {
      setError("Error updating merch");
    }
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton className="bg-light">
        <Modal.Title>
          Edit Brand & Product {props.merch.id}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        {error && <p className="text-danger">{error}</p>}
        <Form>
          <Form.Group className="mb-3" controlId="merchEditForm.brand">
            <Form.Label>Brand</Form.Label>
            <Form.Control
              className="bg-light"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="merchEditForm.category">
            <Form.Label>Category</Form.Label>
            <Form.Control
              className="bg-light"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="merchEditForm.product">
            <Form.Label>Product</Form.Label>
            <Form.Control
              className="bg-light"
              value={product}
              onChange={(e) => setProduct(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
