import { Chart as ChartJS, CategoryScale } from "chart.js";
import { MatrixController, MatrixElement } from "chartjs-chart-matrix";
import { Chart } from "react-chartjs-2";

ChartJS.register(CategoryScale, MatrixController, MatrixElement);

const OverlayCategoricalOnCategoricalHeatMap = (props) => {
  const { overlay } = props;

  let example = [];
  let xLabels = new Set();
  let yLabels = new Set();
  let formattedYLabels = new Set();
  let formattedXLabels = new Set();
  let maxValue = 0;

  Object.keys(overlay["categorical"]).forEach((key) => {
    xLabels.add(key);
    Object.keys(overlay["categorical"][key]).forEach((key1) => {
      maxValue = Math.max(maxValue, overlay["categorical"][key][key1]);
      yLabels.add(key1);
      example.push({ x: key, y: key1, v: overlay["categorical"][key][key1] });
    });
  });
  let yLabelsArray = Array.from(yLabels);
  let xLabelsArray = Array.from(xLabels);
  // iterate each yLabels and add formattedYLabels if length of yLabels is greater than 10
  yLabels.forEach((label) => {
    if (label.length > 15) {
      formattedYLabels.add(label.substring(0, 15) + "...");
    } else {
      formattedYLabels.add(label);
    }
  });
  xLabels.forEach((label) => {
    if (label.length > 15) {
      formattedXLabels.add(label.substring(0, 15) + "...");
    } else {
      formattedXLabels.add(label);
    }
  });

  function interpolateColor(value, maxValue) {
    const green = { r: 0, g: 107, b: 61 };
    // const orange = { r: 255, g: 104, b: 30 };
    const yellow = { r: 245, g: 245, b: 66};
    const red = { r: 255, g: 33, b: 44 };
    const ratio = value / maxValue;

    const low = green;
    const middle = yellow;
    const high = red;

    // Determine which part of the gradient to use
    if (ratio < 0.5) {
      const t = ratio * 2; // Scale ratio to [0, 1] for this segment
      const r = Math.round(low.r * (1 - t) + middle.r * t);
      const g = Math.round(low.g * (1 - t) + middle.g * t);
      const b = Math.round(low.b * (1 - t) + middle.b * t);
      return `rgba(${r}, ${g}, ${b}, ${0.7 + ratio / 5})`;
    } else {
      const t = (ratio - 0.5) * 2; // Scale ratio to [0, 1] for this segment
      const r = Math.round(middle.r * (1 - t) + high.r * t);
      const g = Math.round(middle.g * (1 - t) + high.g * t);
      const b = Math.round(middle.b * (1 - t) + high.b * t);
      return `rgba(${r}, ${g}, ${b}, ${0.7 + ratio / 5})`;
    }
  }

  const data = {
    datasets: [
      {
        label: "Heap map",
        data: example,
        backgroundColor(context) {
          const value = context.dataset.data[context.dataIndex].v;
          return interpolateColor(value, maxValue);
        },
        borderColor(context) {
          return `rgba(255, 255, 255, 1)`;
        },
        borderWidth: 1,
        width: ({ chart }) => (chart.chartArea || {}).width / xLabels.size,
        height: ({ chart }) => (chart.chartArea || {}).height / yLabels.size,
      },
    ],
  };

  const options = {
    plugins: {
      legend: false,
      tooltip: {
        callbacks: {
          title(context) {
            // tooltip title with x and y labels
            return `X: ${context[0].label} - Y: ${context[0].formattedValue}`;
          },
          label(context) {
            const ele = context.dataset.data[context.dataIndex];
            return [`Count: ${ele.v}`];
          },
        },
      },
      datalabels: {
        formatter: function (value, context) {
          // inside the box label
          return context.dataset.data[context.dataIndex]["v"];
        },
        color: "#fff", // text color
        textShadowColor: "#000",
        textShadowBlur: 4,
      },
    },
    scales: {
      x: {
        type: "category",
        labels: xLabelsArray,
        ticks: {
          display: true,
          callback: function (value, index, values) {
            if (xLabelsArray[index].length > 15) {
              return xLabelsArray[index].substring(0, 10) + "...";
            }
            return xLabelsArray[index];
          },
        },
      },
      y: {
        type: "category",
        labels: yLabelsArray,
        offset: true,
        ticks: {
          display: true,
          callback: function (value, index, values) {
            if (yLabelsArray[index].length > 15) {
              return yLabelsArray[index].substring(0, 10) + "...";
            }
            return yLabelsArray[index];
          },
        },
      },
    },
  };

  return <Chart type="matrix" data={data} options={options} />;
};

export default OverlayCategoricalOnCategoricalHeatMap;
