import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFetch from "use-http";
import SearchableSwitchDropdown from "./SearchableSwitchDropdown";
import GenericFilters from "./GenericFilters";
import useFetchConfig from "../Hooks/useFetchConfig";
import {
  handleFilterGroupSwitchChange,
  useSwitchableSelectionState,
  processFilterGroups,
} from "../Utils/FilterGroupUtils";
import { firstLetterCaps } from "../Utils/utilFunctions";
import useFilterStore from "../Hooks/useFilterStore";

const FiltersModal = ({
  overlayParams,
  id,
  customer,
  setError,
  isDashboard = false,
  metric = null,
  setGraph = null,
}) => {
  const [disabled, setDisabled] = useState(true);
  const {
    overlayOptions,
    overlaySelection,
    setOverlayOptions,
    setOverlaySelection,
  } = overlayParams;
  const {
    groupBy,
    startdate,
    endDate,
    locations,
    cities,
    regions,
    countries,
    selectedLocations,
    selectedCities,
    selectedRegions,
    selectedCountries,
    brands,
    categories,
    products,
    selectedBrands,
    selectedCategories,
    selectedProducts,
    filterGroups,
    filterGroupSwitchStates,
    filterOpen,
    isFiltersRetained,
    setGroupBy,
    setStartDate,
    setEndDate,
    setLocations,
    setCities,
    setRegions,
    setCountries,
    setSelectedLocations,
    setSelectedCities,
    setSelectedRegions,
    setSelectedCountries,
    setBrands,
    setCategories,
    setProducts,
    setSelectedBrands,
    setSelectedCategories,
    setSelectedProducts,
    setFilterGroups,
    setSelectedFilterGroups,
    setFilterGroupSwitchStates,
    handleToggleFilterModal,
    handleIsFiltersRetained,
  } = useFilterStore();
  const fetchConfig = useFetchConfig();
  const { get, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const { t } = useTranslation();

  const {
    switchState: countrySwitch,
    setSwitchState: setCountrySwitch,
    handleAddItem: handleSelectedCountry,
  } = useSwitchableSelectionState(selectedCountries, setSelectedCountries);
  const {
    switchState: regionSwitch,
    setSwitchState: setRegionSwitch,
    handleAddItem: handleSelectedRegion,
  } = useSwitchableSelectionState(selectedRegions, setSelectedRegions);
  const {
    switchState: citySwitch,
    setSwitchState: setCitySwitch,
    handleAddItem: handleSelectedCity,
  } = useSwitchableSelectionState(selectedCities, setSelectedCities);
  const {
    switchState: locationSwitch,
    setSwitchState: setLocationSwitch,
    handleAddItem: handleSelectedLocation,
  } = useSwitchableSelectionState(selectedLocations, setSelectedLocations);
  const {
    switchState: brandSwitch,
    setSwitchState: setBrandSwitch,
    handleAddItem: handleSelectedBrands,
  } = useSwitchableSelectionState(selectedBrands, setSelectedBrands);
  const {
    switchState: categorySwitch,
    setSwitchState: setCategorySwitch,
    handleAddItem: handleSelectedCategories,
  } = useSwitchableSelectionState(selectedCategories, setSelectedCategories);
  const {
    switchState: productSwitch,
    setSwitchState: setProductSwitch,
    handleAddItem: handleSelectedProducts,
  } = useSwitchableSelectionState(selectedProducts, setSelectedProducts);

  const onSwitchChange = (value, isChecked, label) => {
    handleFilterGroupSwitchChange(
      value,
      isChecked,
      label,
      setSelectedFilterGroups,
      setFilterGroupSwitchStates,
    );
  };

  useEffect(() => {
    fetchGenericFilters();
    fetchStores();
    fetchMerch();
    fetchOverlayOptions();
  }, [customer, metric]);

  useEffect(() => {
    if (!metric) return;
    setDisabled(false);
    if (isDashboard) return;
    if (metric.template.override_grouping) {
      setGroupBy(metric.template.override_grouping);
    } else if (metric.default_grouping) {
      setGroupBy(metric.default_grouping);
    }
    if (metric.default_overlay) setOverlaySelection(metric.default_overlay);
    if (!startdate) setStartDate(metric.l2_default_start_date);
    if (!endDate) setEndDate(metric.l2_default_end_date);
  }, [metric]);

  const fetchGenericFilters = async () => {
    const endpoint = isDashboard
      ? `/api/dashboard/${id}/filters`
      : `/api/metric/${id}/filters`;
    let data = await get(endpoint);
    if (response.ok) {
      processFilterGroups(data, setFilterGroups, setFilterGroupSwitchStates)
    } else {
      setTimeout(() => {
        if (!response.ok) setError("Error loading FilterGroups.");
      }, 2000);
    }
  };

  const fetchStores = async () => {
    if (!customer.id) return;
    if (!metric) return;
    if (isDashboard && metric && metric && metric.custom) return;
    const endpoint = isDashboard
      ? `/api/dashboard/${id}/stores`
      : `/api/metric/${id}/stores`;
    let data = await get(endpoint);
    if (response.ok) {
      if (data && !data.error) {
        let locationList = [];
        let cityList = [];
        let regionList = [];
        let countryList = [];
        if (data) {
          data.forEach((store) => {
            locationList.push(store.location);
            cityList.push(store.city);
            regionList.push(store.region);
            countryList.push(store.country);
          });
        }
        setLocations([...new Set(locationList)].sort());
        setCities([...new Set(cityList)].sort());
        setRegions([...new Set(regionList)].sort());
        setCountries([...new Set(countryList)].sort());
        setLocationSwitch(
          locationList.reduce((acc, item) => ({ ...acc, [item]: false }), {}),
        );
        setCitySwitch(
          cityList.reduce((acc, item) => ({ ...acc, [item]: false }), {}),
        );
        setRegionSwitch(
          regionList.reduce((acc, item) => ({ ...acc, [item]: false }), {}),
        );
        setCountrySwitch(
          countryList.reduce((acc, item) => ({ ...acc, [item]: false }), {}),
        );
        setError(null);
      }
    } else {
      setTimeout(() => {
        if (!response.ok) setError("Error loading stores.");
      }, 2000);
    }
  };

  const fetchMerch = async () => {
    if (!customer.id) return;
    if (isDashboard && metric && metric && metric.custom) return;
    const endpoint = isDashboard
      ? `/api/dashboard/${id}/merch`
      : `/api/metric/${id}/merch`;
    let data = await get(endpoint);
    if (response.ok) {
      let brandList = [];
      let categoryList = [];
      let productList = [];
      data.forEach((merch) => {
        brandList.push(merch.brand);
        categoryList.push(merch.category);
        productList.push(merch.product);
      });
      setBrands([...new Set(brandList)].sort());
      setCategories([...new Set(categoryList)].sort());
      setProducts([...new Set(productList)].sort());
      setBrandSwitch(
        brandList.reduce((acc, item) => ({ ...acc, [item]: false }), {}),
      );
      setCategorySwitch(
        categoryList.reduce((acc, item) => ({ ...acc, [item]: false }), {}),
      );
      setProductSwitch(
        productList.reduce((acc, item) => ({ ...acc, [item]: false }), {}),
      );
      setError(null);
    } else {
      setTimeout(() => {
        if (!response.ok) setError("Error loading merch.");
      }, 2000);
    }
  };

  const fetchOverlayOptions = async () => {
    if (isDashboard) return;
    let data = await get(`/api/metric/${id}/overlays`);
    if (response.ok) {
      setError(null);
      setOverlayOptions(data);
    } else {
      setTimeout(() => {
        if (!response.ok) setError("Error loading overlay options.");
      }, 2000);
    }
  };

  const _renderFilter = () => {
    const _renderGenericFilterGroupings = () => {
      if (Object.keys(filterGroups).length > 1) {
        return (
          <>
            {Object.entries(filterGroups).map(([key, values]) => (
              <option value={key}>{t(key)}</option>
            ))}
          </>
        );
      }
      return null;
    };

    const _renderStoreFilters = () => {
      if (
        locations.length > 1 ||
        cities.length > 1 ||
        regions.length > 1 ||
        countries.length > 1
      ) {
        return (
          <Row>
            {countries.length > 1 && (
              <Col key={"countries"}>
                <Form.Label>{t("Country")}</Form.Label>
                <SearchableSwitchDropdown
                  options={countries}
                  switchStates={countrySwitch}
                  onSwitchChange={handleSelectedCountry}
                  placeholder={t("Filter")}
                />
              </Col>
            )}
            {regions.length > 1 && (
              <Col key={"regions"}>
                <Form.Label>{t("Region")}</Form.Label>
                <SearchableSwitchDropdown
                  options={regions}
                  switchStates={regionSwitch}
                  onSwitchChange={handleSelectedRegion}
                  placeholder={t("Filter")}
                />
              </Col>
            )}
            {cities.length > 1 && (
              <Col key={"city"}>
                <Form.Label>{t("City")}</Form.Label>
                <SearchableSwitchDropdown
                  options={cities}
                  switchStates={citySwitch}
                  onSwitchChange={handleSelectedCity}
                  placeholder={t("Filter")}
                />
              </Col>
            )}
            {locations.length > 1 && (
              <Col key={"location"}>
                <Form.Label>{t("Location")}</Form.Label>
                <SearchableSwitchDropdown
                  options={locations}
                  switchStates={locationSwitch}
                  onSwitchChange={handleSelectedLocation}
                  placeholder={t("Filter")}
                />
              </Col>
            )}
          </Row>
        );
      }
    };
    const _renderMerchFilters = () => {
      if (products.length > 1 || categories.length > 1 || brands.length > 1) {
        return (
          <>
            <br />
            <Row>
              {brands.length > 1 && (
                <Col key={"brands"}>
                  <Form.Label>{t("Brand")}</Form.Label>
                  <SearchableSwitchDropdown
                    options={brands}
                    switchStates={brandSwitch}
                    onSwitchChange={handleSelectedBrands}
                    placeholder={t("Filter")}
                    variant="purple"
                  />
                </Col>
              )}
              {categories.length > 1 && (
                <Col key={"categories"}>
                  <Form.Label>{t("Category")}</Form.Label>
                  <SearchableSwitchDropdown
                    options={categories}
                    switchStates={categorySwitch}
                    onSwitchChange={handleSelectedCategories}
                    placeholder={t("Filter")}
                    variant="purple"
                  />
                </Col>
              )}
              {products.length > 1 && (
                <Col key={"products"}>
                  <Form.Label>{t("Product")}</Form.Label>
                  <SearchableSwitchDropdown
                    options={products}
                    switchStates={productSwitch}
                    onSwitchChange={handleSelectedProducts}
                    placeholder={t("Filter")}
                    variant="purple"
                  />
                </Col>
              )}
              <Col key={"mrch0"}></Col>
            </Row>
          </>
        );
      }
    };

    const _renderStoreGroupings = () => {
      return (
        <>
          {countries.length > 1 && (
            <option value="country">{t("Country")}</option>
          )}
          {regions.length > 1 && <option value="region">{t("Region")}</option>}
          {cities.length > 1 && <option value="city">{t("City")}</option>}
          {locations.length > 1 && (
            <option value="location">{t("Location")}</option>
          )}
        </>
      );
    };

    const _renderMerchGroupings = () => {
      return (
        <>
          {brands.length > 1 && <option value="brand">{t("Brand")}</option>}
          {categories.length > 1 && (
            <option value="category">{t("Category")}</option>
          )}
          {products.length > 1 && (
            <option value="product">{t("Product")}</option>
          )}
        </>
      );
    };

    const _renderGroupBy = () => {
      if (
        !isDashboard &&
        metric &&
        metric.template.l2_aggregation !== "photo" &&
        metric.template.l2_aggregation !== "table" &&
        metric.template.override_grouping == null &&
        metric.template.handle !== "ops_checkin_out" &&
        metric.template.handle !== "ops_bookings" &&
        metric.template.handle !== "ops_eos_compliance" &&
        (locations.length > 1 ||
          cities.length > 1 ||
          regions.length > 1 ||
          countries.length > 1 ||
          products.length > 1 ||
          categories.length > 1 ||
          brands.length > 1 ||
          Object.keys(filterGroups).length > 0)
      ) {
        return (
          <>
            <Form.Label>{t("Group By")}</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => {
                setGraph && setGraph(null); // Otherwise, _renderChart tries to render a gauge with bar-chart data.
                setGroupBy(e.target.value);
              }}
              className="bg-light"
              value={groupBy}
            >
              <option value="">{t("None")}</option>
              {metric &&
                (metric.template.handle !== "ops_fulfillment" ||
                  metric.template.handle !== "ops_fulfillment_client") && (
                  <>
                    {_renderStoreGroupings()}
                    {_renderMerchGroupings()}
                  </>
                )}
              {_renderGenericFilterGroupings()}
            </Form.Select>
          </>
        );
      }
      return null;
    };

    const _renderArbitraryOverlays = () => {
      // append to this if statements if you don't want to show overlay dropdown
      if ((isDashboard || metric) && (
        metric.template.l2_aggregation === "table" ||
        // metric.template.l2_aggregation === "categorical" ||
        metric.template.l2_aggregation === "photo" ||
        metric.template.l2_aggregation === "wordcloud" ||
        // metric.template.l2_aggregation === "sumproduct" ||
        metric.template.l2_aggregation === "custom" ||
        metric.template.l2_aggregation === "shift_status" ||
        !overlayOptions || Object.keys(overlayOptions).length === 0
      )) {
        return null;
      }
      return (
        <>
          <Form.Label>{t("Overlay")}</Form.Label>
          <Form.Select
            aria-label="setOverlaySelection"
            onChange={(e) => {
              setOverlaySelection(e.target.value);
            }}
            className="bg-light"
            value={overlaySelection}
          >
            <option value="None">{t("None")}</option>
            {_renderAllMetricOptions()}
          </Form.Select>
        </>
      );
    };
    const _renderAllMetricOptions = () => {
      if (overlayOptions && Object.keys(overlayOptions).length > 0) {
        return (
          <>
            {Object.entries(overlayOptions).map(([key, value]) => (
              <option value={key}>{t(key)}</option>
            ))}
          </>
        );
      }
      return null;
    };

    return (
      <>
        {!isDashboard && (
          <Row>
            <Col className="mb-3">
              {_renderGroupBy()}
              {_renderArbitraryOverlays()}
            </Col>
          </Row>
        )}
        <Row>
          {!disabled && (
            <Col className="d-flex flex-column justify-content-end">
              <Form.Label>Preset Ranges</Form.Label>
              <Form.Select
                onChange={(e) => {
                  let [start, end] = e.target.value.split(",");
                  setStartDate(start);
                  setEndDate(end);
                }}
                className="bg-light mb-3"
                disabled={disabled}
              >
                <option value="">None</option>
                {metric &&
                  metric.resolved_date_ranges &&
                  metric.resolved_date_ranges.map((preset, index) => (
                    <option
                      value={preset.dates}
                      startdate={preset.dates[0]}
                      key={"preset-" + index}
                    >
                      {firstLetterCaps(preset.range_type)}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          )}
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="dashboardForm.startdate">
              <Form.Label>{t("Start Date")}</Form.Label>
              <Form.Control
                type="date"
                value={startdate || ""}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                className="bg-light"
              />
            </Form.Group>
          </Col>
          <Col className="d-flex flex-column justify-content-end">
            <Form.Group className="mb-3" controlId="dashboardForm.endDate">
              <Form.Label>{t("End Date")}</Form.Label>
              <Form.Control
                type="date"
                value={endDate || ""}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                className="bg-light"
              />
            </Form.Group>
          </Col>
        </Row>
        {_renderStoreFilters()}
        {_renderMerchFilters()}
        <GenericFilters
          filterGroups={filterGroups}
          filterGroupSwitchStates={filterGroupSwitchStates}
          onSwitchChange={onSwitchChange}
          t={t}
        />
      </>
    );
  };

  return (
    <Modal show={filterOpen} onHide={handleToggleFilterModal}>
      <Modal.Header closeButton className="bg-light">
        <Modal.Title>{t("Filter")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">{_renderFilter()}</Modal.Body>
      <Modal.Footer className="bg-light">
        <Form className="me-auto">
          <div className="justify-content-center">
            <Form.Check
              label={`Filters Retained`}
              onChange={handleIsFiltersRetained}
              checked={isFiltersRetained}
            />
          </div>
        </Form>
        <Button
          variant="primary"
          className="text-white"
          onClick={handleToggleFilterModal}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FiltersModal;
