import React from "react";
import { Chart } from "react-chartjs-2";
import { graphColors } from "../../../Utils/GraphColors";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { sortObjectByKeys, imagePlugin, modalCategoryOverlayOptions, fillMissingKeys, twoLinearYScales } from "../../../Utils/chartJsOptions";
import { simplifyDataForNotGauge } from "../../../Utils/utilFunctions";
import GroupBarChart from "../Simple/GroupBarChart";
import { applyTargetAnnotationsToGrouped } from "../../../Utils/utilFunctions";

export default function OverlayNumericWeatherGroupBarChart(props) {
    // chartData -> numeric metric
    // overlay -> weather
    const { chartData, targets, overlay, fraction } = props;
    if (typeof (overlay) == 'undefined' || overlay == null || !overlay.value) {
        return <GroupBarChart chartData={chartData} targets={targets} fraction={fraction} />;
    }

    // // Handle Gauge type adjustment if necessary
    simplifyDataForNotGauge(chartData.value);
    simplifyDataForNotGauge(overlay.value);

    let filledChartData = JSON.parse(JSON.stringify(chartData.value));
    let filledOverlayData = JSON.parse(JSON.stringify(overlay.value));

    fillMissingKeys(filledOverlayData, chartData.value);
    fillMissingKeys(filledChartData, overlay.value);
    filledChartData = sortObjectByKeys(filledChartData);
    filledOverlayData = sortObjectByKeys(filledOverlayData);

    function extractOverlayValue(obj) {
        return obj && typeof obj === 'object' && 'value' in obj ? obj.value : obj;
    }
    function extractOverlayUrl(obj) {
        return obj && typeof obj === 'object' && 'value' in obj ? obj.url : obj;
    }
    const chartJsSetup = {
        labels: Object.keys(filledChartData),
        datasets: [
            {
                label: chartData.name,
                data: Object.values(filledChartData).map(extractOverlayValue),
                backgroundColor: graphColors[0], // Single color for all chartData bars
                borderRadius: 8,
                yAxisID: "y",
            },
            {
                label: overlay.name,
                data: Object.values(filledOverlayData).map(extractOverlayValue),
                backgroundColor: graphColors[1], // Different color for all overlay bars
                borderRadius: 8,
                yAxisID: "yR",
                imageUrl: Object.values(filledOverlayData).map(extractOverlayUrl),
            },
        ],
    };

    let options = modalCategoryOverlayOptions(overlay, chartData, chartData.y_max, true, true);
    options.scales = twoLinearYScales(options, chartData, overlay);
    options = applyTargetAnnotationsToGrouped(options, targets, chartData);

    return (
        <Chart
            type="bar"
            data={chartJsSetup}
            options={options}
            plugins={[ChartDataLabels, imagePlugin]}
        />
    );
}
