import { CardHeader, Card, Form, Col } from "react-bootstrap";

// const exampleProps = {
//   externalState: chartLabel,
//   setExternalState: setChartLabel,
//   cardHeader: "Chart Label",
//   controlId: "metricTemplateCreate.chartLabel",
//   label:
//     "the idea was, to allow us to label the default bars as something other than value when requested, however, they aren't labelled at all by default; this is ingested & added to MetricTemplate objects, but isn't actually used *by* metrics (yet?)",
//   placeholder: "eg. minutes",
//   maxLength: 12,
// };

export default function SimpleTextInputCard({
  externalState,
  setExternalState,
  cardHeader,
  controlId,
  label,
  placeholder,
  maxLength,
  disabled=false,
}) {
  const handleFormChange = (event) => {
    setExternalState(event.target.value);
  };

  return (
    <Col>
      <Card className="h-100">
        <CardHeader>{cardHeader}</CardHeader>
        <Card.Body className="flex-grow-1 d-flex flex-column">
          <Form.Group
            controlId={controlId}
            className="flex-grow-1 d-flex flex-column"
          >
            <Form.Label>{label}</Form.Label>
            <Form.Control
              onChange={handleFormChange}
              placeholder={placeholder}
              className="mt-auto"
              maxLength={maxLength}
              disabled={disabled}
            />
          </Form.Group>
        </Card.Body>
        <Card.Footer className="text-center">{externalState}</Card.Footer>
      </Card>
    </Col>
  );
}
