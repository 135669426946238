import { useState, useContext } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/Card";
import useFetch from "use-http";
import { useNavigate } from "react-router-dom";

import { MainContext } from "../Providers/MainContext";
import { useTranslation } from "react-i18next";

export default function LoginPage() {
  const navigate = useNavigate();
  const { post, response } = useFetch(process.env.REACT_APP_API_URL, {
    cachePolicy: "no-cache",
  });
  const { setJwt } = useContext(MainContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const _handleSubmit = async () => {
    let data = await post("/api/token", { username, password });
    if (response.ok) {
      setError(null);
      setJwt(data.access, {
        days: 1,
        SameSite: "Strict",
        Secure: true,
      });
    } else {
      setError("Invalid username or password; note that both are case-sensitive.");
    }
  };

  return (
    <Col xs={6} md={4} lg={3} xl={2} className="mx-auto">
      <br />
      <Image src="/static/logo-white.png" fluid />
      <br />
      <br />
      <Card>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="loginForm.email">
              <Form.Label>{t("Username")}</Form.Label>
              <Form.Control
                type="username"
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="loginForm.password">
              <Form.Label>{t("Password")}</Form.Label>
              <Form.Control
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDownCapture={(e) => {
                  if (e.key === "Enter") {
                    _handleSubmit();
                  }
                }}
              />
            </Form.Group>
            <a
              className="text-primary"
              onClick={() => { navigate(`/forgot-password`) }}
            >
              {" "}
              {t("Forgot Password?")}
            </a>
            <br />
            <br />
            {error && <Alert variant="danger">{error}</Alert>}
            <div className="d-grid gap-2">
              <Button
                className="rounded-pill text-white"
                variant="primary"
                onClick={_handleSubmit}
              >
                {t("Login")}
              </Button>
            </div>
            <hr />
            <div className="d-grid gap-2">
              <Button
                variant="secondary"
                className="rounded-pill"
                onClick={() => {
                  navigate("/registration");
                }}
              >
                {t("Sign Up")}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Col>
  );
}
