import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import useFetch from "use-http";
import { useTranslation } from "react-i18next";
import ZipDownloader from "./Gallery/ZipDownloader";
import "chart.js/auto";

import useFetchConfig from "../../Hooks/useFetchConfig";
import useOverlay from "../../Hooks/useOverlay";
import FiltersModal from "../../Components/FiltersModal";
import FilterBadges from "../../Components/FilterBadges";
import { MainContext } from "../../Providers/MainContext";
import { Gallery } from "./Gallery/Gallery";
import { VideoGallery } from "./Gallery/VideoGallery";
import OpsCheckinOutCard from "./Ops/OpsCheckinOutCard";
import OpsBookingsCard from "./Ops/OpsBookingsCard";
import OpsOverworkCard from "./Ops/OpsOverwork";
import OpsTermsConditions from "./Ops/OpsTermsConditions";
import {
  ClientFulfillmentCard,
  OpsFulfillmentCard,
} from "./Ops/FulfillmentCard";
import EosComplianceCard from "./Ops/EosComplianceCard";
import MetricBox from "./MetricBox";
import { temperatureUnit } from "../../Utils/utilFunctions";
import useFilterStore from "../../Hooks/useFilterStore";
import RenderChart from "./RenderChart";
import LoadingSpinner from "./LoadingSpinner";

export default function MetricPage() {
  const fetchConfig = useFetchConfig();
  const { get, patch, response } = useFetch(
    process.env.REACT_APP_API_URL,
    fetchConfig,
  );
  const { id } = useParams();
  const { customer } = useContext(MainContext);
  const overlayParams = useOverlay();
  const { overlayOptions, overlaySelection, overlayData, setOverlayData } =
    overlayParams;
  const filterParams = useFilterStore();
  const { groupBy, getUrlParams, handleToggleFilterModal } = filterParams;

  const [metric, setMetric] = useState(null);
  const [graph, setGraph] = useState(null);
  const [error, setError] = useState(null);
  const [showGallery, setShowGallery] = useState(false);
  const [showVideoGallery, setShowVideoGallery] = useState(false);
  const videoLimit = 9;
  // const [videoLimit, setVideoLimit] = useState(9);  // Use this if we ever let use select how many videos to see at once.
  const [isLoading, setIsLoading] = useState(false);
  // deliberateRefreshes is an input to the useEffects that call the L1 and L2 data.
  // When the shift status table refreshes, we want to pull data again, so we need the input to change
  const [deliberateRefreshes, setDeliberateRefreshes] = useState(0);
  const [extraBoxParams, setExtraBoxParams] = useState([]);

  const { t } = useTranslation();

  // const [fractionDropdown, setFractionDropdown] = useState("");
  const [categoricalDropdown, setCategoricalDropdown] = useState("pie"); // default dropdown is pie

  useEffect(() => {
    if (!customer) return;
    fetchMetric();
  }, [customer, deliberateRefreshes]);

  useEffect(() => {
    if (typeof getUrlParams !== "function") return;
    fetchGraph();
    updateExtraBoxes();
  }, [getUrlParams, deliberateRefreshes]);

  useEffect(() => {
    if (metric && metric.template.l2_aggregation === "photo") {
      setShowGallery(true);
    }
    if (metric && metric.template.l2_aggregation === "video") {
      setShowVideoGallery(true);
    }
  }, [metric]);

  useEffect(() => {
    if (typeof getUrlParams !== "function") return;
    fetchOverlayData(overlaySelection);
  }, [overlayOptions, overlaySelection, getUrlParams]);

  const fetchMetric = async () => {
    let data = await get(`/api/metric/${id}`);
    if (response.ok) {
      setError(null);
      setMetric(data);
      if (data.template.override_grouping || data.default_grouping) {
        setCategoricalDropdown("count");
      }
    } else {
      setTimeout(() => {
        if (!response.ok) setError("Error loading metric.");
      }, 2000);
    }
  };

  const fetchGraph = async () => {
    setIsLoading(true);
    let data = await get(`/api/metric/${id}/graph?` + getUrlParams());
    if (response.ok) {
      setError(null);
      if (JSON.stringify(graph) !== JSON.stringify(data)) {
        // If groupBy is set or data comes back with a group_by, they have to match; otherwise, check back with backend.
        if (
          ((groupBy || data.params?.group_by) &&
            groupBy === data.params.group_by) ||
          (!groupBy && !data.params?.group_by)
        ) {
          setGraph(data);
        }
      }
    } else {
      setError("Error loading graph.");
    }
    setIsLoading(false);
  };

  const updateExtraBoxes = () => {
    if (metric && metric.extra_metricboxes) {
      const extraBoxes = metric.extra_metricboxes;
      const eBP = [];
      // Iterate over each key (like 'region', 'job_code') in extra_metricboxes
      Object.entries(extraBoxes).forEach(([key, values]) => {
        values.forEach((value) => {
          const params = new URLSearchParams(getUrlParams());
          // Override the specific key (like 'region' or 'job_code') with the current value
          params.set(key, value);
          const paramsFunction = () => {
            return params;
          };
          eBP.push([paramsFunction, value]);
        });
      });
      // Set the result (array of URLSearchParams objects) to state
      setExtraBoxParams(eBP);
    }
  };

  const fetchOverlayData = async (overlaySelection) => {
    if (!overlayOptions || Object.keys(overlayOptions).length === 0) return;
    if (overlaySelection === "None" || overlaySelection === "") {
      if (overlayData !== null) setOverlayData(null);
      return;
    }
    setIsLoading(true);
    const overlayId = overlayOptions[overlaySelection].id;
    const overlayType = overlayOptions[overlaySelection].type;
    let data;
    if (overlayType === "metric") {
      data = await get(
        `/api/metric/${id}/metric-overlay/${overlayId}?` + getUrlParams(),
      );
      if (data.params.group_by) {
        if (overlaySelection === "Weather") {
          for (let [key, item] of Object.entries(data.value)) {
            item.value = temperatureUnit(item.value);
            item.temp_c = temperatureUnit(item.temp_c);
            item.min_temp_c = temperatureUnit(item.min_temp_c);
            item.max_temp_c = temperatureUnit(item.max_temp_c);
            data.value[key] = item;
          }
        }
      } else {
        if (overlaySelection === "Weather" && data && data.value) {
          data.value.value = temperatureUnit(data.value.value);
          data.value.temp_c = temperatureUnit(data.value.temp_c);
          data.value.min_temp_c = temperatureUnit(data.value.min_temp_c);
          data.value.max_temp_c = temperatureUnit(data.value.max_temp_c);
        }
      }
    }
    if (overlayType === "grouping") {
      data = await get(
        `/api/metric/${id}/filtergroup-overlay/${overlayId}?` + getUrlParams(),
      );
    }
    if (response.ok) {
      setError(null);
      if (JSON.stringify(overlayData) !== JSON.stringify(data)) {
        setOverlayData(data);
      }
    } else {
      setError("Error loading overlay.");
    }
    setIsLoading(false);
    return;
  };

  function createRefreshFunctionBase({
    patchFn,
    setIsLoading,
    setDeliberateRefreshes,
    deliberateRefreshes
  }) {
    // Returns a function that only needs the patchUrl
    return function (patchUrl) {
      // This returned function is the actual "refresh" function that you can use
      return async function refresh() {
        setIsLoading(true);
        const response = await patchFn(patchUrl);
        if (response.ok) {
          setDeliberateRefreshes(deliberateRefreshes + 1);
        }
        setIsLoading(false);
      };
    };
  }
  const getRefreshFunction = createRefreshFunctionBase({
    patchFn: patch,
    setIsLoading,
    setDeliberateRefreshes,
    deliberateRefreshes
  });
  const refreshShiftStatus = getRefreshFunction('/api/exec/shift_status');
  const refreshEosCompliance = getRefreshFunction('/api/ops/eos_compliance');  

  const fetchCsvData = async () => {
    setIsLoading(true);
    let data = await get(`/api/metric/${id}/exports/csv?${getUrlParams()}`);
    if (response.ok) {
      const downloadUrl = URL.createObjectURL(
        new Blob([data], { type: "text/csv" }),
      );
      const link = document.createElement("a");
      link.href = downloadUrl;
      const datePart = new Date().toISOString().replaceAll(":", ".");
      const filename = `${metric.template.name.split(" ").join("-").toLowerCase()}_${datePart}.csv`;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } else {
      setError("Error loading .csv data");
    }
    setIsLoading(false);
  };

  const _renderPhotoCount = () => {
    if (graph && metric && metric.template.l2_aggregation === "photo") {
      return <span>({graph.value.length})</span>;
    }
  };

  const _renderChart = () => {
    if (isLoading || !graph) return <LoadingSpinner />;
    if (graph.type === "custom") {
      return _renderCustomChart();
    } else {
      return (
        <RenderChart
          chartData={graph}
          overlayData={overlayData}
          categoricalDropdown={categoricalDropdown}
        />
      );
    }
  };

  const _renderCustomChart = () => {
    const customParams = {
      data: graph,
      filterParams: filterParams,
      handleExport: fetchCsvData,
      isLoading: isLoading,
      dropdownSelection: categoricalDropdown,
      deliberateRefreshes: deliberateRefreshes,
      setDeliberateRefreshes: setDeliberateRefreshes,
      refreshEosCompliance: refreshEosCompliance,
    };
    const componentMapping = {
      ops_checkin_out: OpsCheckinOutCard,
      ops_overwork: OpsOverworkCard,
      ops_bookings: OpsBookingsCard,
      ops_terms_conditions: OpsTermsConditions,
      client_fulfillment: ClientFulfillmentCard,
      ops_fulfillment: OpsFulfillmentCard,
      client_timesheet_fulfillment: ClientFulfillmentCard,
      ops_eos_compliance: EosComplianceCard,
    };
    const Component = componentMapping[metric.template.handle];
    if (Component) {
      return <Component {...customParams} />;
    }
  };

  const displayTopRow = () => {
    return (
      <>
        <FiltersModal
          overlayParams={overlayParams}
          id={id}
          customer={customer}
          setError={setError}
          isDashboard={false}
          metric={metric}
          setGraph={setGraph}
        />
        {metric.template.l2_aggregation !== "custom" && (
          <>
            <Row
              className="mx-auto align-items-start"
              style={{
                display: "flex",
                flexWrap: "wrap",
                position: "relative",
                width: "67vw",
              }}
            >
              <Col md={5}>
                <h4 className="text-light m-1">
                  {" "}
                  {metric.template.name} {_renderPhotoCount()}
                </h4>
              </Col>
              <Col
                md={2}
                className="d-flex justify-content-start justify-content-md-center align-items-center"
              >
                {metric.template.l2_aggregation === "shift_status" && (
                  <Button
                    onClick={refreshShiftStatus}
                    className="btn btn-primary text-white m-1"
                    style={{ height: "70px" }}
                  >
                    {t("Refresh")}
                  </Button>
                )}
                <Button
                  onClick={fetchCsvData}
                  className="btn btn-primary text-white m-1"
                  style={{ height: "70px" }}
                >
                  Export
                </Button>
                {metric.template.l2_aggregation === "photo" && (
                  <ZipDownloader
                    urls={graph?.value || []}
                    setError={setError}
                  />
                )}
                <Button
                  onClick={handleToggleFilterModal}
                  className="btn btn-primary text-white m-1"
                  style={{ height: "70px" }}
                >
                  {t("Filter")}
                </Button>
              </Col>

              {/* <Dropdown show={filterOpen}>
                <Dropdown.Toggle onClick={() => setFilterOpen(!filterOpen)}>
                  {t("Filter")}
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ padding: 24, minWidth: "500px" }}>
                  {" "}
                  <Accordion.Item>
                    <Accordion.Body>{_renderFilter()}</Accordion.Body>
                  </Accordion.Item>
                </Dropdown.Menu>
              </Dropdown> */}
              <Col
                md={5}
                className="d-flex justify-content-start justify-content-md-end"
              >
                {graph &&
                  (graph.type === "categorical" ||
                    graph.type === "pie_table" ||
                    graph.type === "cat_axb") &&
                  !overlayData && (
                    <Form.Select
                      aria-label="Fraction Graph Options"
                      value={categoricalDropdown}
                      onChange={(e) => {
                        e.stopPropagation();
                        setCategoricalDropdown(e.target.value);
                      }}
                      style={{ width: "auto" }}
                      className="m-1"
                    >
                      <option value="percentage">Percentage</option>
                      <option value="count">Count</option>
                      <option value="pie">Pie</option>
                      {/* Add more options as needed */}
                    </Form.Select>
                  )}
                {/* Requires implementation of count-type fraction chart */}
                {/* {graph && graph.type === 'fraction' && (
                <Form.Select
                  aria-label="Fraction Graph Options"
                  value={fractionDropdown}
                  onChange={(e) => setFractionDropdown(e.target.value)}
                >
                  <option value="percentage">Percentage</option>
                  <option value="count">Count</option>
                </Form.Select>
              )} */}
              </Col>
            </Row>
            <br />
          </>
        )}
        <FilterBadges filterParams={filterParams} t={t} />
      </>
    );
  };

  return (
    <Container fluid className="d-flex justify-content-start">
      <Col key="metricBox" className="d-none d-xl-inline">
        <Row className="mx-auto">
          <Col>
            <br />
            <br />
            <br />
            <Row>
              <MetricBox
                metricId={id}
                index={id}
                sidebar={true}
                getUrlParams={getUrlParams}
              />
            </Row>
            {extraBoxParams &&
              extraBoxParams.map((box, index) => (
                <>
                  <Row>
                    <MetricBox
                      metricId={id}
                      index={id + 2 * (index + 1)}
                      sidebar={true}
                      getUrlParams={box[0]}
                      nameSuffix={" - " + box[1]}
                    />
                  </Row>
                </>
              ))}
          </Col>
        </Row>
      </Col>

      <Col key="main" className="mx-auto">
        <br />
        <br />
        {error && <Alert variant="danger">{error}</Alert>}
        {metric && metric.length === 0 && <p>No metric found</p>}
        {metric && (
          <>
            {displayTopRow()}
            <Row className="mx-auto align-items-start">
              <br />
              {/* Gallery and Chart conditional rendering */}
              {/* may require refactoring if needed */}
              <Col>
                {(() => {
                  if (showGallery) {
                    return <Gallery urls={graph?.value || []} />;
                  } else if (showVideoGallery) {
                    return (
                      <VideoGallery
                        urls={graph?.value || []}
                        videoLimit={videoLimit}
                      />
                    );
                  } else {
                    return (
                      <Card
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          backgroundColor: "white",
                          position: "relative",
                          minHeight: "75vh",
                          width: "66vw",
                        }}
                        className="mx-auto"
                      >
                        {_renderChart()}
                      </Card>
                    );
                  }
                })()}
              </Col>
            </Row>
            <Row style={{ height: "50px" }}></Row>
          </>
        )}
      </Col>
      <Col key="balance" className="d-none d-xl-inline"></Col>
    </Container>
  );
}
