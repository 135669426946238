import React from "react";
import { Card, CardHeader, Col, Row } from "react-bootstrap";
import {
  OpsTable,
  MiniButton,
  TitleButton,
  HeadlineButtons,
  ExportButton,
  FiltersButton,
  RefreshButton,
  Cell,
} from "./utils";
import {
  dbToLocale,
  formatStdDateLocaleTime,
  formatTimeToLocale,
} from "../../../Utils/utilFunctions";

export default function OpsCheckinOutCard({
  data,
  filterParams,
  handleExport,
  isLoading,
  deliberateRefreshes,
  setDeliberateRefreshes,
}) {
  const {
    groupBy,
    setGroupBy,
    selectedFilterGroups,
    setSelectedFilterGroups,
    handleToggleFilterModal,
  } = filterParams;
  const rows = data.value.rows;
  if (rows === undefined) return <></>;
  const headline = data.value.headline;
  const columns = data.value.columns;
  const primaryButtonColumns = [
    "Required",
    "Booked",
    "Check-Ins",
    "Not Checked In",
    "Check-Outs",
    "Not Checked Out",
  ];
  const treatLikeBooked = ["Required", "Booked"];
  const secondary = data.value.secondary;
  const secondaryButtonColumns = ["n/a"];
  const cellToColor = (row, column) => {
    let variantDenom = "Booked";
    let variantNum = column;
    // Disable lint re: default case
    // eslint-disable-next-line
    switch (column) {
      case "Required":
        variantNum = "Booked";
      // eslint-disable-next-line
      case "Booked":
        variantDenom = "Required";
        break;
      case "Check-Outs":
        variantDenom = "Checked In";
        break;
      case "Not Checked Out":
        variantNum = "Checked Out";
        variantDenom = "Checked In";
        break;
      case "Checked In":
      case "Not Checked In":
        variantNum = "Checked In";
        break;
    }
    const calc = row[variantDenom]
      ? (1.0 * row[variantNum]) / row[variantDenom]
      : 0.5;
    let variantColor = "primary";

    if (column === "Required") {
      // Always primary.
    } else if (calc < 0.5) {
      variantColor = "danger";
    } else if (calc < 0.8 || (treatLikeBooked.includes(column) && calc < 1)) {
      variantColor = "purple";
    }
    return variantColor;
  };
  const buttonConfig = [
    {
      content: `Bookings: ${headline.actual_value} / ${headline.size} = ${headline.value}%`,
      tooltip: "Bookings data for all jobs.",
    },
    {
      content: `Checkins: ${headline.checked_in} / ${headline.booked_started} = ${headline.checked_in_percentage}%`,
      tooltip: "Checkin data for all jobs.",
    },
    {
      content: `Self-Checked-In: ${headline.self_checked_in} / ${headline.checked_in} = ${headline.self_checked_in_percentage}%`,
      tooltip: "Self-checkin data for all jobs.",
    },
    {
      content: `Self-Checked-Out: ${headline.self_checked_out} / ${headline.checked_out} = ${headline.self_checked_out_percentage}%`,
      tooltip: "Self-checkout data for all jobs.",
    },
  ];
  const times = ["First Shift", "Start", "End"];
  const assignCell = (row, column, rowIndex, args) => {
    const key = `${rowIndex}-${column}`;
    const buttonColumns = args["buttonColumns"];
    let value = row[column];
    if (times.includes(column)) {
      value = formatTimeToLocale(row[column], dbToLocale(row["db"]));
    } else if (column === "Checked In" || column === "Checked Out") {
      value = formatStdDateLocaleTime(row[column], dbToLocale(row["db"]));
    }
    if (buttonColumns.includes(column) && groupBy !== column) {
      return (
        <MiniButton
          key={key}
          row={row}
          column={column}
          updateConfig={[
            { key: "job_id", source: "ID" },
            { key: "db", source: "db" },
          ]}
          cellToColor={cellToColor}
          setGroupBy={setGroupBy}
          selectedFilterGroups={selectedFilterGroups}
          setSelectedFilterGroups={setSelectedFilterGroups}
          isLoading={isLoading}
        />
      );
    } else if (column === "Job Name") {
      return (
        <Cell
          key={key}
          value={
            <a
              href={`https://portal-${row["db"]}.weareelevate.global/jobs/${row["ID"]}`}
              target="_blank"
              rel="noreferrer"
            >
              {row["Job Name"]}
            </a>
          }
          tooltip={column}
        />
      );
    } else if (column === "Freelancer ID") {
      return (
        <Cell
          key={key}
          clss="text-end"
          value={
            <a
              href={`https://portal-${selectedFilterGroups["db"]}.weareelevate.global/administration/freelancers/profile/${row["Freelancer ID"]}`}
              target="_blank"
              rel="noreferrer"
            >
              {row["Freelancer ID"]}
            </a>
          }
          tooltip={column}
        />
      );
    } else if (column === "db") {
      return (
        <Cell
          key={key}
          value={
            <a
              href={`https://portal-${row["db"]}.weareelevate.global/checkins`}
              target="_blank"
              rel="noreferrer"
            >
              {row["db"]}
            </a>
          }
          tooltip={column}
        />
      );
    } else if (
      row[column] !== null &&
      (typeof row[column] === "number" ||
        row[column].endsWith("%") ||
        row[column] === "n/a")
    ) {
      return <Cell key={key} clss="text-end" value={value} tooltip={column} />;
    } else if (
      column === "Start" &&
      row["Started"] === "1" &&
      row["Checked In"] === null
    ) {
      return <Cell key={key} clss="bg-danger" value={value} tooltip={column} />;
    } else {
      return <Cell key={key} clss="" value={value} tooltip={column} />;
    }
  };

  const header = () => {
    return (
      <Row className="d-flex justify-content-between align-items-center">
        <Col>
          {groupBy ? (
            <TitleButton
              title={data.name}
              deleteKeys={["job_id", "db"]}
              setGroupBy={setGroupBy}
              setSelectedFilterGroups={setSelectedFilterGroups}
              isLoading={isLoading}
            />
          ) : (
            <h5>{data.name}</h5>
          )}
        </Col>
        <Col className="mx-auto"></Col>
        <Col xs="auto">
          <HeadlineButtons buttonConfig={buttonConfig} isLoading={isLoading} />
          <ExportButton isLoading={isLoading} handleExport={handleExport} />
          <FiltersButton
            isLoading={isLoading}
            handleToggleFilterModal={handleToggleFilterModal}
          />
          <RefreshButton
            isLoading={isLoading}
            deliberateRefreshes={deliberateRefreshes}
            setDeliberateRefreshes={setDeliberateRefreshes}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Card>
      <CardHeader>{header()}</CardHeader>
      <Card.Body>
        <OpsTable
          columns={columns}
          rows={rows}
          assignCell={assignCell}
          args={{ buttonColumns: primaryButtonColumns }}
        />
        {secondary && (
          <OpsTable
            columns={secondary.columns}
            rows={secondary.rows}
            assignCell={assignCell}
            args={{ buttonColumns: secondaryButtonColumns }}
          />
        )}
      </Card.Body>
      <Card.Footer></Card.Footer>
    </Card>
  );
}
