import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";

const CustomerDropdown = ({
  customers,
  canImpersonate,
  setImpersonatedCustomerId,
}) => {
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term
  const [isOpen, setIsOpen] = useState(false); // Track dropdown state
  const searchInputRef = useRef(null);
  const navigate = useNavigate();

  // Filter customers based on the search term
  const filteredCustomers = customers.filter((customer) =>
    customer.title.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <>
      {canImpersonate && customers && (
        <NavDropdown
          title="Customer Selector"
          id="basic-nav-dropdown"
          onToggle={() => setIsOpen(!isOpen)}
          // show={isOpen}
        >
          {/* Search input field */}
          <Form.Control
            type="text"
            className="mx-3 my-2 w-auto bg-light placeholder-grey"
            placeholder="Search customers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            ref={searchInputRef}
          />

          {/* Dropdown menu */}
          <ul style={{ listStyle: "none", padding: 0 }}>
            {filteredCustomers.map((customer) => (
              <NavDropdown.Item
                key={customer.id}
                onClick={() => {
                  setImpersonatedCustomerId(customer.id);
                  navigate("/");
                }}
              >
                {customer.title}
              </NavDropdown.Item>
            ))}
          </ul>
        </NavDropdown>
      )}
    </>
  );
};

export default CustomerDropdown;
