import React, { useRef, useEffect } from "react";
import { Chart } from 'chart.js';
import { blackColor } from "../../../Utils/GraphColors";
import { arraysToObject, concatenateArrays } from "../../../Utils/utilFunctions";
import { chartJsFormats } from "../../../Utils/chartJsOptions";


export function GenericGaugeSingleChart({chartData, gaugeConditions, gaugeRangeLabels, gaugeColors, gaugeRanges, startValue, metricTitle, rotationFunction}) {
    const canvasRef = useRef(null);
    const chartRef = useRef(null);
    const gaugeLegends = concatenateArrays(gaugeConditions, gaugeRangeLabels, ': ');
    const gaugeGraphColors = arraysToObject(gaugeConditions, gaugeColors);
    let dataLabelOptions = chartJsFormats((value, context) => {
        return `${context.chart.data.labels[context.dataIndex]}`;
    })["plugins"]["datalabels"]
    const fontSettings = (context) => {
        let width = context.chart.width;
        let size = Math.round(width / 50);
        return {...dataLabelOptions["font"], size: size}
    }
    dataLabelOptions["rotation"] = rotationFunction
    dataLabelOptions["font"] = fontSettings

    useEffect(() => {
        // the first value returns the average gauge value
        // the second returns the count 
        const [gaugeValue, gaugeCount] = chartData.value;
        const gaugeNeedle = {
            id: 'gaugeNeedle',
            afterDatasetsDraw(chart, args, plugins) {
                const { ctx, data } = chart;

                ctx.save();
                const xCenter = chart.getDatasetMeta(0).data[0].x;
                const yCenter = chart.getDatasetMeta(0).data[0].y;
                const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
                const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
                const widthSlice = (outerRadius - innerRadius) / 2;
                const radius = 15;

                // If the needle doesn't start from 0, this needs an adjustment.
                const needleValue = data.datasets[0].needleValue - startValue;
                const circumference = ((chart.getDatasetMeta(0).data[0].circumference / Math.PI) / data.datasets[0].data[0]) * needleValue;

                ctx.translate(xCenter, yCenter);
                ctx.rotate(Math.PI * (circumference + 1.5));

                // needle
                ctx.beginPath();
                ctx.strokeStyle = blackColor;
                ctx.fillStyle = blackColor;
                ctx.lineWidth = 1;
                // the triangle
                ctx.moveTo(0 - radius, -50);
                ctx.lineTo(0, 0 - innerRadius - widthSlice);
                ctx.lineTo(0 + radius, -50);
                ctx.closePath();
                ctx.stroke();
                ctx.fill();
                ctx.restore();

            }
        }

        // the number below the needle
        const gaugeFlowMeter = {
            id: 'gaugeFlowMeter',
            afterDatasetsDraw(chart, args, plugins) {
                const { ctx, data } = chart;

                ctx.save();
                const needleValue = data.datasets[0].needleValue;
                const xCenter = chart.getDatasetMeta(0).data[0].x;
                const yCenter = chart.getDatasetMeta(0).data[0].y;

                // flowMeter
                ctx.font = 'bold 30px ' + Chart.defaults.font.family;
                ctx.textAlign = 'center';
                ctx.fillText(`${needleValue}`, xCenter, yCenter);
            }
        }

        const config = {
            type: 'doughnut',
            data: {
                labels: gaugeConditions,
                datasets: [{
                    data: gaugeRanges,
                    backgroundColor: gaugeColors,
                    borderWidth: 5,
                    circumference: 180,
                    rotation: 270,
                    cutout: '80%',
                    needleValue: gaugeValue,
                }]
            },
            options: {
                aspectRatio: 2,
                layout: {
                    padding: {
                        bottom: 30
                    },
                },
                plugins: {
                    datalabels: dataLabelOptions,
                    tooltip: {
                        enabled: true,
                        callbacks: {
                            label: function (context) {
                                // Display the label with the actual data value
                                // let label = context.chart.data.labels[context.dataIndex] || '';
                                // if (label) {
                                //     label += ': ';
                                // }
                                // label += actualData[context.dataIndex];
                                // when you hover the gauge, the below label will be displayed
                                return ` ${metricTitle}: ${gaugeValue} (Sample Size: ${gaugeCount})`;
                            }
                        }
                    },
                    legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                            generateLabels: (chart) => {
                                return chart.data.labels.map((label, index) => {
                                    return {
                                        text: gaugeLegends[index],
                                        fillStyle: gaugeGraphColors[label],
                                    };
                                });
                            },
                        }
                    },
                }
            },
            plugins: [gaugeNeedle, gaugeFlowMeter],
        };

        if (canvasRef.current) {
            const ctx = canvasRef.current.getContext('2d');
            chartRef.current = new Chart(ctx, config);
        }

        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
                chartRef.current = null;
            }
        };
    }, [chartData]);

    return <canvas ref={canvasRef} />;
}